import React from 'react';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  size: 'large' | 'small' | 'middle';
}

const SearchIcon: React.FC<SvgProps> = ({ size, ...props }) => (
  <>
    {size === 'large' &&
      <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" stroke="currentColor">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="m28 28-8-8m2.667-6.667a9.333 9.333 0 1 1-18.667 0 9.333 9.333 0 0 1 18.667 0Z"
        />
      </svg>
    }
    {size === 'middle' &&
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5312 17.5327L12.5313 12.5327M14.1979 8.36605C14.1979 11.5877 11.5862 14.1994 8.36452 14.1994C5.14286 14.1994 2.53119 11.5877 2.53119 8.36605C2.53119 5.14439 5.14286 2.53271 8.36452 2.53271C11.5862 2.53271 14.1979 5.14439 14.1979 8.36605Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    }
    {size === 'small' &&
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" stroke="currentColor">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="m21 21-6-6m2-5a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
        />
      </svg>
    }
  </>

);

export default SearchIcon;