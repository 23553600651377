import styled from "styled-components";

interface SearchInputProps {
  textBody: string;
  fieldPlaceholderText: string;
  fieldBackground: string;
}

interface SearchIconContainerProps {
  brandTertiary: string;
}

export const SearchIconContainer = styled.div<SearchIconContainerProps>`
  display: flex;
  cursor: pointer;
  color: ${({ brandTertiary }) => `${brandTertiary}`};
  align-self: center;
  padding: 10px;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const SearchBox = styled.div<any>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  border: 1.5px solid
    ${({ fieldBorder, isFocused, brandPrimary }) =>
      isFocused ? brandPrimary : fieldBorder};
  background: ${({ fieldBackground }) => `${fieldBackground}`};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
    0px 0px 0px 4px rgba(238, 238, 238, 1);
  position: absolute;
  z-index: 2;
  right: ${(props) => (props.open ? "4px" : "0px")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  transform: translateX(${(props) => (props.open ? "0" : "400px")});
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.3s ease-in-out;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  width: 400px;
  height: 48px;
  color: ${({ textBody }) => `${textBody}`};

  @media screen and (max-width: 768px) {
    height: 44px;

    transform: translateX(${(props) => (props.open ? "0" : "200px")});
    width: calc(100vw - 26px);
  }
`;

export const SearchInput = styled.input<SearchInputProps>`
  font-size: 18px;
  font-weight: 400;
  color: ${({ textBody }) => `${textBody}`};
  flex: 1;
  padding: 4px 8px;
  border: none;
  outline: none;
  background: ${({ fieldBackground }) => `${fieldBackground}`};

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: ${({ fieldPlaceholderText }) => `${fieldPlaceholderText}`};
    font-size: 18px;
    font-weight: 400;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
    }
  }
`;

export const CloseWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
