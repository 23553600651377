import styled from "styled-components";

interface IconContainerProps {
  disabled?: boolean;
  backgroundTertiary: string;
  brandPrimary: string;
  size?: "small" | "medium";
  headerModalBackground: string;
  mode?: "standard" | "action";
}

interface LabelContainerProps {
  textBody: string;
  size?: "small" | "medium";
}

export const Container = styled.div<any>`
  display: flex;
  width: ${({ size }) => (size === "medium" ? "132px" : "124px")};

  ${({ mode, backgroundTertiary }) =>
    mode === "action" &&
    `
      padding: 4px;
      background: ${backgroundTertiary};
      border-radius: 58px;
    `}

  justify-content: space-between;
  align-items: center;
`;

export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => (size === "medium" ? "40px" : "32px")};
  width: ${({ size }) => (size === "medium" ? "40px" : "32px")};
  cursor: pointer;
  border-radius: 100%;

  background-color: ${({
    disabled,
    mode,
    backgroundTertiary,
    headerModalBackground,
  }) =>
    disabled
      ? mode === "standard"
        ? `${backgroundTertiary}99`
        : mode === "action"
        ? `${headerModalBackground}99`
        : "inherit"
      : mode === "standard"
      ? backgroundTertiary
      : mode === "action"
      ? headerModalBackground
      : "inherit"};
  color: ${({ disabled, brandPrimary }) =>
    disabled ? `${brandPrimary}66` : brandPrimary};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(95%);
  }
`;

export const LabelContainer = styled.div<LabelContainerProps>`
  font-size: ${({ size }) => (size === "medium" ? "20px" : "18px")};
  font-weight: 600;
  color: ${({ textBody }) => `${textBody}`};

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
