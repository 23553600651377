import { primaryColor } from "../../constants";
// import { SpinnerContainer } from "./spinner.styles";
import ClipLoader from "react-spinners/ClipLoader";

const Spinner = ({ size, color }) => (
    // <SpinnerContainer />
    <ClipLoader
        color={color ? color : primaryColor}
        loading={true}
        cssOverride={{
            display: "block",
            margin: "0 auto",
            borderWidth: "8px",
            borderStyle: "solid",
            borderColor: `rgb(52 64 84 / 20%) rgb(52 64 84 / 20%) ${color ? color : primaryColor}`,
        }}
        speedMultiplier={0.8}
        size={size ? size : 70}
        aria-label="Loading Spinner"
        data-testid="loader"
    />
);

export default Spinner;