import Button from '../button/button.component';

const PaymentButton = ({ buttonType, text, style, isLoading, cartItems, onClick, id, disabled }) => {

    return (
        <Button onClick={onClick} buttonType={buttonType} style={style} isLoading={isLoading} id={id} disabled={disabled}>
            {text}
        </Button>
    )
}

export default PaymentButton;