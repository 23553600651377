import styled from "styled-components";
import { fieldError } from "../../constants";

interface InputProps {
  fieldBorder: string;
  fieldBackground: string;
  fieldPlaceholderText: string;
  brandPrimary: string;
  textBody: string;
  error?: string;
}

interface FormInputLabelProps {
  textBody: string;
}

export const Group = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormInputLabel = styled.label<FormInputLabelProps>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;
  color: ${({ textBody }) => `${textBody}`};

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  position: relative;
`;

export const ErrorIconContainer = styled.span`
  position: absolute;
  right: 14px;
  display: flex;
  height: 100%;
  align-items: center;
`;

export const Input = styled.input<InputProps>`
  display: block;
  width: 100%;
  font-size: 18px;
  padding: ${({ error }) => (error ? "4px 36px 4px 14px" : "4px 14px")};
  font-weight: 400;
  border-radius: 8px;
  background: ${({ fieldBackground }) => `${fieldBackground}`};

  border: 1.5px solid
    ${({ fieldBorder, error }) => (error ? fieldError : fieldBorder)};

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: ${({ textBody }) => `${textBody}`};
  height: 48px;

  &:-webkit-autofill {
    background-color: ${({ fieldBackground }) => `${fieldBackground}`};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px
      ${({ fieldBackground }) => `${fieldBackground}`} inset;
    -webkit-text-fill-color: ${({ textBody }) => `${textBody}`};
  }

  &::placeholder {
    color: ${({ fieldPlaceholderText }) => `${fieldPlaceholderText}`};
    font-size: 18px;
    font-weight: 400;
  }

  &:focus {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px rgba(238, 238, 238, 1);
    outline: none;
    border-color: ${({ brandPrimary, error }) =>
      error ? fieldError : brandPrimary};
    background: ${({ fieldBackground }) => `${fieldBackground}`};
  }

  @media screen and (max-width: 768px) {
    height: 44px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
    }
  }
`;

export const ErrorText = styled.div`
  margin-top: 6px;
  font-size: 16px;
  font-weight: 400px;
  line-height: 24px;
  color: ${fieldError};

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
