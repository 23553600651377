// import { Link } from 'react-router-dom';
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  z-index: 2;
  height: 60px;
  min-height: 60px;
  padding: 0 16px;
  background-color: ${({ headerModalBackground }) => headerModalBackground};
  border-bottom: 1px solid ${({ backgroundTertiary }) => backgroundTertiary};

  @media screen and (max-width: 768px) {
    height: 52px;
    min-height: 52px;
    padding: 0;
  }
`;

export const UnorderedList = styled.ul`
  position: relative;
  max-width: 852px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  height: 60px;
  list-style: none;
  padding: 0;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  gap: 32px;
  overflow-x: auto;

  @media screen and (max-width: 768px) {
    height: 52px;
    gap: 20px;
  }

  a {
    position: relative;
    text-decoration: none;
    padding: 10px 12px;
    font-size: 18px;
    font-weight: 600;
    color: ${({ textBody }) => textBody};

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  a::before {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0px;
    width: 100%;
    height: 3px;
    background-color: transparent;
  }

  a.active {
    color: ${({ brandPrimary }) => brandPrimary};
  }

  a.active::before {
    background-color: ${({ brandPrimary }) => brandPrimary};
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;
