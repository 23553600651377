import { FieldErrorIcon } from "../icons";
import {
  BodyText,
  CheckoutDetailsContainer,
  Title,
  ErrorContainer,
  ErrorText,
} from "./checkout-details.stylex";

const CheckoutDetails = ({ onClick, title, body, error }) => {
  return (
    <CheckoutDetailsContainer onClick={onClick}>
      <Title>{title}</Title>
      {error ? (
        <ErrorContainer>
          <ErrorText>{error}</ErrorText>
          <FieldErrorIcon />
        </ErrorContainer>
      ) : (
        <BodyText>{body}</BodyText>
      )}
    </CheckoutDetailsContainer>
  );
};

export default CheckoutDetails;
