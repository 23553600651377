import styled from "styled-components";
import { baseDark600 } from "../../constants";
import { getFontSize } from "../../utils/utils.common";
const TextBase = styled.span`
  color: #ffffff;
`;

export const MainHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 20px;
  margin: auto;
  position: ${({ checkDrawerState }) =>
    checkDrawerState ? "sticky" : "fixed"};
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: 64px;
  background: ${({ headerModalBackground }) => headerModalBackground};
  border-bottom: 1px solid ${({ backgroundTertiary }) => backgroundTertiary};

  @media screen and (max-width: 768px) {
    padding: 6px 8px;
  }
`;

export const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  background: ${(props) => props.headerModalBackground};
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;

  @media screen and (max-width: 768px) {
    padding: 20px 0 0;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
`;

export const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ specialMargin }) => (specialMargin ? "32px" : "0")};

  @media screen and (max-width: 768px) {
    margin: 0 0 20px;
    width: 100%;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  color: ${({ textBody }) => textBody};
  margin-right: 8px;
  flex-shrink: 0;
`;

export const HeaderTextSmall = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${({ textBody }) => textBody};
  ${({ onClick }) => onClick && "cursor: pointer;"}
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const AdressBox = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${({ textBody }) => textBody};

  @media screen and (min-width: 769px) {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const HeaderTitle = styled.div`
  font-weight: 600;
  color: ${({ brandPrimary }) => brandPrimary};
  font-family: ${({ brandFontStyle }) => brandFontStyle};
  font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 30)};
  display: flex;
  align-items: center;
  text-align: center;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};

  @media screen and (max-width: 768px) {
    font-weight: 600;
    font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 20)};
    line-height: 30px;
    color: ${({ brandPrimary }) => brandPrimary};
  }
`;

export const RestaurantStatus = styled(TextBase)`
  font-weight: 600;
  font-size: 18px;
  color: ${baseDark600};
  padding-left: 5px;
  @media screen and (max-width: 900px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const BodyContainer = styled.div`
  flex: 1;
`;

export const NoticeContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 1;
  display: flex;
  padding: 20px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(187, 187, 187, 0.76);
`;

export const NoticeContainerText = styled(TextBase)`
  font-weight: 500;
  font-size: 20px;
  color: red;
  text-align: center;
  @media screen and (max-width: 900px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const MenuBody = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 32px 0;
  width: 100%;
  background: ${({ backgroundPrimary }) => backgroundPrimary};

  @media screen and (max-width: 768px) {
    padding: 24px 0;
  }
`;

export const BannerImage = styled.div`
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 215px;
`;

export const BannerContainer = styled.div`
  ${({ imageUrl }) =>
    imageUrl
      ? `
        background-image: url(${imageUrl});
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 215px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 12px;
      `
      : `
        width: 100%;
      `}
  margin-top: ${({ checkDrawerState }) => (checkDrawerState ? " " : "64px")};
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
`;
