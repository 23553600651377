import styled from "styled-components";

interface HeaderProps {
  headerModalBackground: string;
}

interface IconContainerProps {
  brandTertiary: string;
  headerModalBackground: string;
}

interface HeaderTextProps {
  brandAccent: string;
}

interface ButtonContainerProps {
  headerModalBackground: string;
  backgroundSecondary: string;
}

interface AddressBoxProps {
  textBody: string;
}

export const Container = styled.div`
  overflow-y: auto;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
`;

export const ContentBox = styled.div`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ headerModalBackground }) => headerModalBackground};
  padding: 6px 10px 6px 16px;
  margin-bottom: 12px;
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 12;

  @media screen and (max-width: 768px) {
    margin-bottom: 7px;
    padding: 10px 10px 10px 16px;
  }
`;

export const HeaderText = styled.div<HeaderTextProps>`
  font-size: 20px;
  font-weight: 600;
  margin-right: 10px;
  color: ${({ brandAccent }) => brandAccent};
`;

export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  cursor: pointer;
  color: ${({ brandTertiary }) => brandTertiary};
  border-radius: 50%;
  align-self: center;
  padding: 10px;
  transition: all 0.2s ease;
  background: ${({ headerModalBackground }) => headerModalBackground};

  &:hover {
    filter: brightness(95%);
  }
`;

export const BoxItem = styled.div`
  padding: 0 24px 32px;

  @media screen and (max-width: 768px) {
    padding: 0 16px 32px;
  }
`;

export const OrderTypeButtonsContainer = styled.div`
  margin-bottom: 32px;

  @media screen and (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

export const MarkerIconContainer = styled.div`
  margin-right: 8px;
`;

export const AddressText = styled.div`
  font-weight: 600;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const AddressBox = styled.div<AddressBoxProps>`
  display: flex;
  margin-bottom: 32px;
  color: ${({ textBody }) => textBody};
  @media screen and (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

export const ButtonContainer = styled.div<ButtonContainerProps>`
  border-top: 1px solid ${({ backgroundSecondary }) => backgroundSecondary};
  position: sticky;
  z-index: 14;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ headerModalBackground }) => headerModalBackground};
  padding: 20px 16px;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
`;
