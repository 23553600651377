import React from 'react';


const ClockIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" stroke="currentColor">
    <g clipPath="url(#a)">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 5v5l3.333 1.667m5-1.667a8.333 8.333 0 1 1-16.666 0 8.333 8.333 0 0 1 16.666 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>


);

export default ClockIcon;