import './menu-item.styles.jsx';
import { BackgroundImage, DescriptionContainer, ImageContainer, MainContainer, NameContainer, PriceContainer, MenuItemContainer, TextTopBox, SoldOutContainer, SoldOutCircle, SoldOutText } from './menu-item.styles.jsx';
import { useState } from 'react';
import { TransitionsModal } from '../menu-item-modal/menu-item-modal.component.jsx';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../utils/firebase/firebase.utils';
import { dishSoldout } from '../../constants.ts';
import { useSelector } from 'react-redux';
import { selectOnlineOrdersConfigs } from '../../store/kitchen/kitchen.selector';
import { getColor } from '../../utils/utils.common';

const MenuItem = ({ product, kitchen }) => {
    const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);
    const textBody = getColor("text-body", onlineOrdersConfig);
    const backgroundPrimary = getColor("background-primary", onlineOrdersConfig);

    const [showModal, setShowModal] = useState(false);
    const toggleShowModal = (e, reason) => {

        logEvent(analytics, "dish_viewed", {
            kitchenName: kitchen?.kitchenName
        });

        setShowModal(p => !p);
    }

    const { dishName, dishDescription, dishPrice, remoteUri, dishStatus } = product;
    // const cartItems = useSelector(selectCartItems);
    const soldOut = dishStatus === dishSoldout;
    // const dispatch = useDispatch();
    // const addProductToCart = () => dispatch(addItemToCart(cartItems, product));
    return (
        <>
            <MenuItemContainer onClick={toggleShowModal} cardBackground={getColor("card-background", onlineOrdersConfig)}>
                {/* <ProductFooterContainer> */}
                <MainContainer>
                    <TextTopBox imageUrl={remoteUri}>
                        <NameContainer soldOut={soldOut} brandTertiary={getColor("brand-tertiary", onlineOrdersConfig)}>{dishName}</NameContainer>
                        <DescriptionContainer soldOut={soldOut} textBody={textBody}>{dishDescription ? dishDescription.replaceAll('\\n', '\n') : ''}</DescriptionContainer>
                    </TextTopBox>
                    <div style={{ flex: 1, alignItems: 'flex-end', display: 'flex' }}>
                        <div style={{ alignItems: 'center', flex: 1, display: 'flex' }}>
                            <PriceContainer soldOut={soldOut} textBody={textBody}>${Number(dishPrice).toFixed(2)}</PriceContainer>
                            {soldOut && (
                                <SoldOutContainer>
                                    <SoldOutCircle textBody={textBody} />
                                    <SoldOutText textBody={textBody} backgroundPrimary={backgroundPrimary}>
                                        {dishStatus}
                                    </SoldOutText>
                                </SoldOutContainer>
                            )}
                        </div>

                    </div>
                </MainContainer>
                {remoteUri && (
                    <ImageContainer>
                        <BackgroundImage className='image' imageUrl={remoteUri} alt={`${dishName}`} soldOut={soldOut}  />
                    </ImageContainer>
                )}
                {/* </ProductFooterContainer> */}
                {/* <AddButton type={BUTTON_TYPE_CLASSES.inverted} onClick={addProductToCart}>Add to cart</AddButton> */}
            </MenuItemContainer>
            {showModal && (
                <TransitionsModal product={product} showModal={showModal} toggleShowModal={toggleShowModal} kitchen={kitchen} />
            )}
        </>
    )
}

export default MenuItem;