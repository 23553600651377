import styled from "styled-components";
import { StatusType } from "./status.component";

interface StatusContainerProps {
  hasBanner?: boolean;
  backgroundSecondary: string;
  backgroundTertiary: string;
  textBody: string;
  forModalStatus?: boolean;
}

interface StatusIconProps {
  status: StatusType;
}

interface SmallCircleProps {
  hasBanner?: boolean;
  textBody: string;
}

export const StatusContainer = styled.div<StatusContainerProps>`
  display: flex;
  align-items: center;
  padding: ${({ forModalStatus }) => (forModalStatus ? "9px" : "11px")};

  ${({
    hasBanner,
    backgroundSecondary,
    backgroundTertiary,
    textBody,
    forModalStatus,
  }) =>
    hasBanner
      ? `
      background: rgba(19, 19, 19, 0.7);
      border-radius: 84px;
      color: #fff;
  `
      : `
    background: ${forModalStatus ? "transparent" : backgroundSecondary}; 
    color: ${textBody};  
    border-top: 1px solid ${
      forModalStatus ? "transparent" : backgroundTertiary
    };
    border-bottom: 1px solid ${
      forModalStatus ? "transparent" : backgroundTertiary
    };
    width: 100%;
    justify-content: center;
  `}
`;

export const StatusIcon = styled.div<StatusIconProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 12px;

  background: ${(props) =>
    props.status === StatusType.Open
      ? "#039855"
      : props.status === StatusType.Close
      ? "#F04438"
      : props.status === StatusType.NotCurrently
      ? "#F38744"
      : "#fff"};

  @media screen and (max-width: 768px) {
    margin-right: 8px;
  }
`;

export const PrimaryText = styled.div<any>`
  font-size: ${({ forModalStatus }) => (forModalStatus ? "20px" : "16px")};
  font-weight: 600;

  @media screen and (max-width: 768px) {
    font-size: ${({ forModalStatus }) => (forModalStatus ? "16px" : "14px")};
  }
`;

export const SecondaryText = styled.div<any>`
  font-size: ${({ forModalStatus }) => (forModalStatus ? "20px" : "16px")};
  font-weight: 500;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: ${({ forModalStatus }) => (forModalStatus ? "16px" : "14px")};
  }
`;

export const SmallCircle = styled.div<SmallCircleProps>`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0px 12px;
  background: ${({ hasBanner, textBody }) => (hasBanner ? "#fff" : textBody)};

  @media screen and (max-width: 768px) {
    margin: 0 8px;
  }
`;
