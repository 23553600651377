import styled from "styled-components";
import { getFontSize } from "../../utils/utils.common";

interface BodyTextProps {
  textBody: string;
}

interface KitchenNameProps {
  brandPrimary: string;
  brandFontStyle: string;
}

interface HamburgerIconContainerProps {
  headerModalBackground: string;
  brandTertiary: string;
}

interface PoweredByBoxProps {
  backgroundTertiary: string;
  headerModalBackground: string;
}

interface PoweredByTextProps {
  textBody: string;
}

export const KitchenName = styled.div<KitchenNameProps>`
  font-family: ${({ brandFontStyle }) => brandFontStyle};
  font-weight: 600;
  font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 24)};
  color: ${({ brandPrimary }) => brandPrimary};
  @media screen and (max-width: 768px) {
    font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 20)};
  }
`;
export const Header = styled.span<any>`
  font-family: ${({ brandFontStyle }) => brandFontStyle};
  font-weight: 600;
  font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 20)};

  margin-bottom: 4px;
  line-height: 28px;
  color: ${({ color }) => `${color}`};
  @media screen and (max-width: 768px) {
    font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 18)};
  }
`;

export const BodyText = styled.div<BodyTextProps>`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: ${({ textBody }) => textBody};

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const BoxBase = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 8px 24px;

  @media screen and (max-width: 768px) {
    padding: 20px 20px 12px 20px;
  }
`;

export const ContentBox = styled(BoxBase)``;

export const HamburgerIconContainer = styled.div<HamburgerIconContainerProps>`
  display: flex;
  cursor: pointer;
  color: ${({ brandTertiary }) => brandTertiary};
  border-radius: 100%;
  align-self: center;
  padding: 10px;
  transition: all 0.2s ease;
  background: ${({ headerModalBackground }) => headerModalBackground};
  &:hover {
    filter: brightness(95%);
  }
`;

export const PoweredByBox = styled.div<PoweredByBoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  height: 52px;
  padding: 10px 20px;
  position: sticky;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 5;
  border-top: 1px solid ${({ backgroundTertiary }) => backgroundTertiary};
  background: ${({ headerModalBackground }) => headerModalBackground};
`;

export const PoweredByText = styled.div<PoweredByTextProps>`
  font-size: 14px;
  font-weight: 600;
  color: ${({ textBody }) => textBody};
  margin-right: 2px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
