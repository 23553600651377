import React from 'react';
import { StatusContainer, PrimaryText, SecondaryText, StatusIcon, SmallCircle } from './status.styles';
import { useSelector } from 'react-redux';
import { selectOnlineOrdersConfigs } from '../../../store/kitchen/kitchen.selector';
import { getColor } from '../../../utils/utils.common';

export enum StatusType {
  Open = 'open',
  Close = 'close',
  Visual = 'visual',
  NotCurrently = 'notCurrently',
}

type StatusProps = {
  status: StatusType;
  primaryText: string;
  secondaryText?: string;
  statusCircle?: boolean;
  hasBanner?: boolean;
  forModalStatus?: boolean;
};

const Status: React.FC<StatusProps> = ({ status, primaryText, secondaryText, statusCircle = true, hasBanner, forModalStatus = false }) => {
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);
  
  return (
    <StatusContainer hasBanner={hasBanner} backgroundSecondary={getColor("background-secondary", onlineOrdersConfig)}
      backgroundTertiary={getColor("background-tertiary", onlineOrdersConfig)}
      textBody={getColor("text-body", onlineOrdersConfig)}
      forModalStatus={forModalStatus}
    >
      {statusCircle &&
        <StatusIcon status={status} />
      }
      <PrimaryText forModalStatus={forModalStatus}>{primaryText}</PrimaryText>
      {secondaryText && <SecondaryText forModalStatus={forModalStatus}><SmallCircle hasBanner={hasBanner} textBody={getColor("text-body", onlineOrdersConfig)} />{secondaryText}</SecondaryText>}
    </StatusContainer>
  );
};

export default Status