import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOrderNote } from "../../store/cart/cart.action";
import { selectOrderNote } from "../../store/cart/cart.selector";
import Note from "../shared/note/note.component";


const OrderNoteBottomSheet = () => {
  const dispatch = useDispatch();
  const orderNote = useSelector(selectOrderNote);

  const onBlurAction = (text: string) => {
    dispatch(setOrderNote(text));
  }

  const onClearAction = () => {
    dispatch(setOrderNote(null));
  }

  return (
    <Note
      title="Order Note"
      buttonText='Add Order Note'
      size='medium'
      defaultValue={orderNote}
      onBlurAction={onBlurAction}
      onClearAction={onClearAction}
    />
  );
};

export default OrderNoteBottomSheet;
