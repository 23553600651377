import React from 'react';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  size: 'large' | 'small';
}

const FacebookIcon: React.FC<SvgProps> = ({ size, ...props }) => (
  <>
    {size === 'large' &&
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10440_84995)">
          <path d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z" fill="currentColor" />
        </g>
        <defs>
          <clipPath id="clip0_10440_84995">
            <rect width="24" height="24" />
          </clipPath>
        </defs>
      </svg>
    }
    {size === 'small' &&
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10440_43899)">
          <path d="M20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 14.9912 3.65684 19.1283 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29063 9.93047 3.90625 12.2146 3.90625C13.3084 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1922C11.95 6.5625 11.5625 7.3334 11.5625 8.125V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3432 19.1283 20 14.9912 20 10Z" fill="currentColor" />
        </g>
        <defs>
          <clipPath id="clip0_10440_43899">
            <rect width="20" height="20" />
          </clipPath>
        </defs>
      </svg>
    }
  </>

);

export default FacebookIcon;