import React from 'react';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  size: 'large' | 'small' | 'middle';
}

const CloseIcon: React.FC<SvgProps> = ({ size, ...props }) => (
  <>
    {size === 'large' &&
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 8L8 24M8 8L24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    }
    {size === 'middle' &&
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    }
    {size === 'small' &&
      <svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} fill="none" stroke="currentColor">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.67}
          d="m15.031 5.033-10 10m0-10 10 10"
        />
      </svg>
    }
  </>
);

export default CloseIcon;