import React, { useState } from "react";
import { Container, DayBox, ArowIconContainer, DayText, OpenTimeBox, TimeContainer, TimeItem, NoAvailableTime } from "./schedule.styles";
import { ArowSmallIcon } from "../icons";
import { useSelector } from "react-redux";
import { selectKitchen, selectOnlineOrdersConfigs } from "../../store/kitchen/kitchen.selector";
import { getColor } from "../../utils/utils.common";
import { generateAvailableTimeSlots } from "../../utils/closedKitchenCheck";
import moment from "moment";

interface ScheduleProps {
  currentScheduledTime: any;
  setCurrentScheduledTime: any;
  dayOffset: number;
  setDayOffset: any;
}

const Schedule: React.FC<ScheduleProps> = ({ currentScheduledTime, setCurrentScheduledTime, dayOffset, setDayOffset }) => {
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

  const backgroundTertiary = getColor("background-tertiary", onlineOrdersConfig);
  const brandPrimary = getColor("brand-primary", onlineOrdersConfig);
  const textBody = getColor("text-body", onlineOrdersConfig);

  const kitchen = useSelector(selectKitchen);
  const schedule = kitchen.hours.schedule;
  const orderWaitTime = onlineOrdersConfig?.orderReadyTime
    ? Number(onlineOrdersConfig?.orderReadyTime)
    : 15;

  const maxDays = 7;

  const { increments, openingTime } = generateAvailableTimeSlots(schedule, dayOffset, orderWaitTime);

  const displayDate = dayOffset === 0 ? "Today" : moment().add(dayOffset, "days").format("MMM D");

  return (
    <Container backgroundTertiary={backgroundTertiary}>
      <DayBox>
        <ArowIconContainer style={{ transform: 'rotate(180deg)' }} backgroundTertiary={backgroundTertiary} brandPrimary={brandPrimary}
          onClick={() => setDayOffset((prev) => (prev > 0 ? prev - 1 : 0))}
          disabled={dayOffset === 0}
        >
          <ArowSmallIcon />
        </ArowIconContainer>
        <DayText textBody={textBody}>
          {displayDate}
        </DayText>
        <ArowIconContainer backgroundTertiary={backgroundTertiary} brandPrimary={brandPrimary}
          onClick={() => setDayOffset((prev) => (prev < maxDays - 1 ? prev + 1 : maxDays - 1))}
          disabled={dayOffset === maxDays - 1}
        >
          <ArowSmallIcon />
        </ArowIconContainer>
      </DayBox>
      <OpenTimeBox textBody={textBody}>
        {openingTime && `Opening time at ${openingTime}`}
      </OpenTimeBox>
      <TimeContainer>
        {increments.length > 0 ? increments.map((increment, index) => {
          return (
            <TimeItem
              key={index}
              onClick={() => setCurrentScheduledTime({ dayOffset, time: increment, displayDate })}
              isSelected={currentScheduledTime?.dayOffset === dayOffset && currentScheduledTime?.time === increment}
              brandPrimary={brandPrimary}
              textBody={textBody}
              backgroundTertiary={backgroundTertiary}
            >
              {increment}
            </TimeItem>
          );
        })
          : <NoAvailableTime textBody={textBody}>No available times</NoAvailableTime>
        }
      </TimeContainer>
    </Container>
  );
};

export default Schedule;