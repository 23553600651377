import styled from "styled-components";

interface TimeItemProps {
  brandPrimary: string;
  textBody: string;
  backgroundTertiary: string;
  isSelected: boolean;
}

interface ContainerProps {
  backgroundTertiary: string;
}

interface ArowIconContainerProps {
  backgroundTertiary: string;
  brandPrimary: string;
  disableButton?: boolean;
}

interface OpenTimeBoxProps {
  textBody: string;
}

export const Container = styled.div<ContainerProps>`
  margin-top: 32px;
  padding-top: 20px;
  border-top: 4px solid ${({ backgroundTertiary }) => backgroundTertiary};

  @media screen and (max-width: 768px) {
    margin-top: 24px;
  }
`;

export const DayBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
`;

export const ArowIconContainer = styled.button<ArowIconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: ${({ backgroundTertiary, disabled }) =>
    disabled ? `${backgroundTertiary}99` : backgroundTertiary};
  color: ${({ disabled, brandPrimary }) =>
    disabled ? `${brandPrimary}66` : brandPrimary};
  cursor: pointer;
  border: none;

  &:not(:disabled):hover {
    filter: brightness(95%);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const DayText = styled.div<OpenTimeBoxProps>`
  font-size: 20px;
  font-weight: 600;
  color: ${({ textBody }) => `${textBody}`};

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const OpenTimeBox = styled.div<OpenTimeBoxProps>`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: ${({ textBody }) => `${textBody}`};

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const TimeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  row-gap: 10px;

  @media screen and (max-width: 768px) {
    row-gap: 12px;
  }
`;

export const TimeItem = styled.div<TimeItemProps>`
display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;

  background: ${({ isSelected, backgroundTertiary }) =>
    isSelected ? backgroundTertiary : "transparent"};

  outline: ${({ isSelected, brandPrimary, textBody }) =>
    isSelected ? `2px solid ${brandPrimary}` : `1px solid ${textBody}`};

  color: ${({ isSelected, brandPrimary, textBody }) =>
    isSelected ? brandPrimary : textBody};

  font-weight: ${({ isSelected }) => (isSelected ? "600" : "500")};

  @media screen and (max-width: 768px) {
    height: 52px;
    font-size: 18px;
  }
`;

export const NoAvailableTime = styled.div<OpenTimeBoxProps>`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: ${({ textBody }) => `${textBody}`};
`;