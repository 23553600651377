import React, { useState, useEffect } from "react";
import {
  ChoicesText,
  ExtraChargeText,
  VarientCategoryContainer,
  Subtitle,
  Title,
  VariantHeaderContainer,
  VariantsContainer,
  VarientCategoryText,
  InputContainer,
  ChoicesBox,
} from "./menu-variants.styles";
import { useSelector } from "react-redux";
import { selectOnlineOrdersConfigs } from "../../store/kitchen/kitchen.selector";
import { isOnlineOrderConfigValid } from "../../utils/utils.common";
import { useTextColor } from "../../utils/colorContrast/useColorContrast";

function containsNumbers(str) {
  return /\d/.test(str);
}

function removeSpecialCharacters(str) {
  return str.replace(/[^a-zA-Z0-9]/g, "").replace(/\s/g, "");
}

export const MenuVariants = ({
  setDishPriceVariant,
  dishPriceVariant,
  maxCounter,
  minCounter,
  optionCategory,
  optionNumber,
  options,
  setVariantSelected,
  variantSelected,
  mandatoryChoice,
  setMinimumVariantsSelected,
  kitchenTier,
  brandTertiary,
  textBody,
  backgroundTertiary,
  headerModalBackground,
  backgroundSecondary,
}) => {
  const [boxesChecked, setBoxesChecked] = useState(-1);
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

  const themeColor = useTextColor(headerModalBackground);

  function optionSelected(selection, i, type) {
    let selectedCheckbox;
    if (containsNumbers(selection.name)) {
      selectedCheckbox = document.querySelector(
        `#${removeSpecialCharacters(selection.name)}`
      );
    } else {
      selectedCheckbox = document.querySelector(
        `#${removeSpecialCharacters(selection.name)}`
      );
    }

    setVariantSelected(prevState => {
      let updatedPrice = dishPriceVariant;

      if (type === "radio") {
        const prevSelected = prevState.find(
          item => item.optionCategory === optionCategory
        );

        if (prevSelected && prevSelected.extraCharge) {
          updatedPrice -= Number(prevSelected.charge);
        }

        if (selection.extraCharge) {
          updatedPrice += Number(selection.charge);
        }

        setDishPriceVariant(updatedPrice);

        // Filter out any previous selections in the same category, then add the new radio button selection.
        const filteredPrevState = prevState.filter(
          item => item.optionCategory !== optionCategory
        );

        return [
          ...filteredPrevState,
          {
            ...selection,
            id: `${i}-${optionNumber}`,
            optionCategory: optionCategory,
            mandatoryChoice: mandatoryChoice,
          },
        ];
      } else {
        // ... (Checkbox logic remains the same)
        if (!selectedCheckbox) return prevState;
        if (!selectedCheckbox.checked) {
          updatedPrice -= selection.extraCharge ? Number(selection.charge) : 0;
          setDishPriceVariant(updatedPrice);

          return prevState.filter(item => item.id !== `${i}-${optionNumber}`);
        } else {
          updatedPrice += selection.extraCharge ? Number(selection.charge) : 0;
          setDishPriceVariant(updatedPrice);

          return [
            ...prevState,
            {
              ...selection,
              id: `${i}-${optionNumber}`,
              optionCategory: optionCategory,
              mandatoryChoice: mandatoryChoice,
            },
          ];
        }
      }
    });

    setBoxesChecked(
      document.querySelectorAll(`.checkbox-${optionNumber}:checked`).length
    );
  }

  function getCheckboxes(checkboxNumber) {
    var boxes = document.getElementsByClassName(`checkbox-${checkboxNumber}`);
    return [...boxes];
  }
  useEffect(() => {
    const checkboxes = getCheckboxes(optionNumber);

    // Count all the mandatory selections to enable add to order button
    const mandatoryChoicesCount = variantSelected.filter(
      item => item.mandatoryChoice
    ).length;

    setMinimumVariantsSelected(mandatoryChoicesCount);

    if (maxCounter !== null && maxCounter !== minCounter) {
      if (boxesChecked >= maxCounter) {
        // Enable all boxes when between min and max
        checkboxes.forEach(box => {
          if (!box.checked) {
            box.disabled = true;
          }
        });
      } else {
        // Re-enable boxes when boxesChecked is less than max counter
        checkboxes.forEach(box => {
          box.disabled = false;
        });
      }
    }
  }, [mandatoryChoice, variantSelected]);
  return (
    <VariantsContainer
      backgroundTertiary={backgroundTertiary}
      headerModalBackground={headerModalBackground}
    >
      <VariantHeaderContainer>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Title brandTertiary={brandTertiary}>{optionCategory}</Title>
          </div>

        </div>
        {onlineOrdersConfig !== null &&
          onlineOrdersConfig?.isTyroLocationIdValid && (
            <div className="category__tag">
              {mandatoryChoice ? (
                <VarientCategoryContainer className="required-varient">
                  <VarientCategoryText>Required</VarientCategoryText>
                </VarientCategoryContainer>
              ) : (
                <VarientCategoryContainer className="optional-varient">
                  <VarientCategoryText>Optional</VarientCategoryText>
                </VarientCategoryContainer>
              )}
            </div>
          )}
      </VariantHeaderContainer>

      <Subtitle textBody={textBody}>
        {minCounter === maxCounter && maxCounter === null && `Choose Any`}

        {minCounter === maxCounter &&
          maxCounter !== null &&
          `Exactly ${minCounter !== null ? minCounter : maxCounter}`}

        {minCounter !== 0 &&
          minCounter !== null &&
          maxCounter !== 0 &&
          maxCounter !== null &&
          minCounter !== maxCounter &&
          `Between ${minCounter} and ${maxCounter}`}

        {minCounter !== 0 &&
          (maxCounter === 0 || maxCounter === null) &&
          minCounter !== maxCounter &&
          `At least ${minCounter}`}

        {(minCounter === 0 || minCounter === null) &&
          maxCounter !== 0 &&
          minCounter !== maxCounter &&
          `Up to ${maxCounter}`}
      </Subtitle>
      <div style={{ paddingTop: 16 }}>
        {options?.map((selections, i) => {
          return onlineOrdersConfig !== null &&
            onlineOrdersConfig?.isTyroLocationIdValid ? (
            <fieldset
              key={i}
              style={{
                borderWidth: "0px",
                alignContent: "center",
                display: "flex",
                padding: "6px 0 6px",
                margin: "0px",
              }}
            >
              <label style={{ display: "flex", width: "100%" }}>
                {isOnlineOrderConfigValid(onlineOrdersConfig) ? (
                  <InputContainer
                    textBody={textBody}
                    backgroundSecondary={backgroundSecondary}
                    themeColor={themeColor}
                  >
                    <input
                      id={removeSpecialCharacters(selections?.name)}
                      type={
                        maxCounter === 1 && minCounter === 1
                          ? "radio"
                          : "checkbox"
                      }
                      name={`action-${optionNumber}`}
                      value="track"
                      className={`checkbox checkbox-${optionNumber}`}
                      onChange={() =>
                        optionSelected(
                          selections,
                          i,
                          maxCounter === 1 && minCounter === 1
                            ? "radio"
                            : "checkbox"
                        )
                      }
                      size="0px"
                      style={{ cursor: "pointer" }}
                    />
                  </InputContainer>
                ) : (
                  <div>-</div>
                )}
                <div
                  id={removeSpecialCharacters(selections?.name)}
                  className={`checkbox-${optionNumber}`}
                  style={{ display: "flex", flexBasis: "100%" }}
                >
                  <ChoicesBox>
                    <ChoicesText textBody={textBody}>
                      {selections?.name}{" "}
                    </ChoicesText>
                    {selections.extraCharge && (
                      <ExtraChargeText textBody={textBody}>
                        +${Number(selections.charge).toFixed(2)}
                      </ExtraChargeText>
                    )}
                  </ChoicesBox>
                </div>
              </label>
            </fieldset>
          ) : (
            <ul style={{ paddingLeft: 0, listStyle: "none" }}>
              <li style={{ paddingTop: 6, paddingBottom: 6 }}>
                <ChoicesBox>
                  <ChoicesText textBody={textBody}>
                    {selections?.name}{" "}
                  </ChoicesText>
                  {selections.extraCharge && (
                    <ExtraChargeText textBody={textBody}>
                      +${Number(selections.charge).toFixed(2)}
                    </ExtraChargeText>
                  )}
                </ChoicesBox>
              </li>
            </ul>
          );
        })}
      </div>
      {/* <hr /> */}
    </VariantsContainer>
  );
};
