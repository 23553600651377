import React, { useEffect, useRef, useState } from "react";
import {
  SearchContainer,
  SearchIconContainer,
  SearchBox,
  SearchInput,
  CloseWrapper,
} from "./search.styles";
import { useMediaQuery } from "../../../utils/utils.common";
import { CloseIcon, SearchIcon } from "../../icons";

interface ButtonGroupProps {
  fieldBorder: string;
  fieldBackground: string;
  fieldPlaceholderText: string;
  textBody: string;
  brandTertiary: string;
  brandPrimary: string;
  openSearch: boolean;
  setOpenSearch: (s: boolean) => void;
}

const Search: React.FC<ButtonGroupProps> = ({
  fieldBorder,
  fieldBackground,
  fieldPlaceholderText,
  textBody,
  brandTertiary,
  brandPrimary,
  openSearch,
  setOpenSearch,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);


  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFocused]);


  const openSearchHandler = () => {
    setOpenSearch(true);
    setIsFocused(true)
  };

  const closeSearchHandler = () => {
    setOpenSearch(false);
  };

  return (
    <SearchContainer>
      <SearchIconContainer
        onClick={openSearchHandler}
        brandTertiary={brandTertiary}
      >
        <SearchIcon size={isMobile ? "small" : "large"} />
      </SearchIconContainer>
      <SearchBox
        open={openSearch}
        isFocused={isFocused}
        fieldBorder={fieldBorder}
        fieldBackground={fieldBackground}
        textBody={textBody}
        fieldPlaceholderText={fieldPlaceholderText}
        brandPrimary={brandPrimary}
      >
        <SearchIcon size="middle" />
        <SearchInput
          ref={inputRef}
          placeholder="Start Searching"
          onFocus={() => setIsFocused(true)}
          fieldPlaceholderText={fieldPlaceholderText}
          textBody={textBody}
          fieldBackground={fieldBackground}
        />
        <CloseWrapper onClick={closeSearchHandler}>
          <CloseIcon size="small" />
        </CloseWrapper>
      </SearchBox>
    </SearchContainer>
  );
};

export default Search;
