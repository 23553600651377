import styled from "styled-components";

interface ButtonStyleProps {
  buttonBackground?: string;
  buttonTextColor?: string;
}

interface BaseButtonProps {
  buttonBackground?: string;
  buttonTextColor?: string;
}

export const BaseButton = styled.button<BaseButtonProps>`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  height: 48px;

  color: ${({ disabled, buttonTextColor }) =>
    disabled ? `${buttonTextColor}66` : buttonTextColor};

  background-color: ${({ disabled, buttonBackground }) =>
    disabled ? `${buttonBackground}99` : buttonBackground};

  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
  transition: background-color 0.2s ease;


  &:hover {
    filter: brightness(95%);
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    height: 44px;
  }
`;

export const GoogleSignInButton = styled(BaseButton)`
  background-color: #4285f4;
  color: white;

  &:hover {
    background-color: #357ae8;
    border: none;
  }
`;

export const CartButton = styled(BaseButton)<ButtonStyleProps>`
  max-width: 300px;
  color: ${({ buttonTextColor }) => buttonTextColor};
  background-color: ${({ buttonBackground }) => buttonBackground};
  transition: background-color 0.2s ease;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &:hover {
    filter: brightness(95%);
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const InvertedButton = styled(BaseButton)`
  box-sizing: border-box;
  background-color: white;
  color: black;
  border-radius: 8px;
  padding: 16px 32px 16px 32px;
  height: 56px;
  border: 1px solid #000000;

  /* Auto layout
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start; */

  &:hover {
    background-color: purple;
    color: white;
    border: none;
  }
`;
