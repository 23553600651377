import {
  FooterBg,
  StandardContainer,
  FooterRestInfo,
  FooterRestName,
  FooterInfoPowered,
  FooterInfoSmall,
  FooterInfoLinks,
  FooterInfoCopyright,
  License,
  Divider
} from "./footer.styles";
import pretzelLogo from "../../assets/pretzel-logo.png";
import pretzelLogoWhite from "../../assets/pretzel-logo-white.png";
import { useSelector } from "react-redux";
import { selectKitchen } from "../../store/kitchen/kitchen.selector";
import { selectOnlineOrdersConfigs } from "../../store/kitchen/kitchen.selector";
import { Link } from "@mui/material";
import { isOnlineOrderConfigValid, getColor, useMediaQuery, getFontName } from "../../utils/utils.common";
import { FacebookIcon, InstagramIcon } from "../icons";
import { useTextColor } from "../../utils/colorContrast/useColorContrast";
import { WhiteAndBlack } from "../../utils/colorContrast/colorContrast";

const Footer = ({ hideName }) => {
  const kitchen = useSelector(selectKitchen);
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);
  const textBody = getColor("text-body", onlineOrdersConfig);
  const brandTertiary = getColor("brand-tertiary", onlineOrdersConfig);
  const backgroundSecondary = getColor("background-secondary", onlineOrdersConfig);
  const brandFontStyle = getFontName("brand-font-style", onlineOrdersConfig);

  const isMobile = useMediaQuery('(max-width: 768px)');
  const logoColor = useTextColor(backgroundSecondary);

  return (
    <FooterBg backgroundSecondary={backgroundSecondary} backgroundTertiary={getColor("background-tertiary", onlineOrdersConfig)}>
      <StandardContainer>
        <FooterRestInfo>
          <FooterRestName
            brandAccent={getColor("brand-accent", onlineOrdersConfig)}
            brandFontStyle={brandFontStyle}
          >
            {kitchen?.kitchenName}
            {kitchen?.kitchenLicense && <License textBody={textBody}>{kitchen?.kitchenLicense}</License>}
          </FooterRestName>
            <div className="footer-rest-right">
              {!!onlineOrdersConfig?.facebookURL && (
                <Link
                  href={onlineOrdersConfig.facebookURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: 'inline-flex', color: textBody, ml: 2.5, '&:hover': { color: brandTertiary, },
                  }}
                >
                  <FacebookIcon size={isMobile ? 'small' : 'large'} />
                </Link>
              )}
              {!!onlineOrdersConfig?.instagramURL && (
                <Link
                  href={onlineOrdersConfig.instagramURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: 'inline-flex', color: textBody, ml: 2.5, '&:hover': { color: brandTertiary, },
                  }}
                >
                  <InstagramIcon size={isMobile ? 'small' : 'large'} />
                </Link>
              )}
            </div>
        </FooterRestInfo>
      </StandardContainer>
      <Divider backgroundTertiary={getColor("background-tertiary", onlineOrdersConfig)} />
      <StandardContainer>
        <FooterInfoPowered textBody={textBody}>
          <span style={{marginRight: '2px'}}>Powered by</span>
          {<img src={logoColor === WhiteAndBlack.black ? pretzelLogo : pretzelLogoWhite} alt="Pretzel logo" height="19px" />}
        </FooterInfoPowered>
        <FooterInfoSmall>
          <FooterInfoCopyright textBody={textBody}>
            © {new Date().getFullYear()}
            <span>PRETZEL RESTAURANT POS</span>
          </FooterInfoCopyright>
          <FooterInfoLinks
            brandTertiary={brandTertiary}
          >
            <a href="https://www.pretzelpos.com.au/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            <a href="https://www.pretzelpos.com.au/terms-conditions" target="_blank" rel="noopener noreferrer">
              Terms & Conditions
            </a>
          </FooterInfoLinks>
        </FooterInfoSmall>
      </StandardContainer>
    </FooterBg>
  );
};

export default Footer;
