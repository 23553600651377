import React, { useEffect, useRef, useState } from "react";
import { Drawer, useMediaQuery } from "@mui/material";
import Button from "../button/button.component";
import FormInput from "../form-input/form-input.component";
import { useDispatch, useSelector } from "react-redux";
import {
  setScheduledTime,
} from "../../store/cart/cart.action";
import { selectKitchen } from "../../store/kitchen/kitchen.selector";
import { getColor } from "../../utils/utils.common";
import { selectScheduledTime } from "../../store/cart/cart.selector";
import OrderTypeButtonGroup from "../shared/buttonGroup/orderTypeButtonGroup.component";
import { CloseIcon, MarkerIcon } from "../icons";
import {
  Container,
  Header,
  HeaderText,
  IconContainer,
  OrderTypeButtonsContainer,
  BoxItem,
  AddressBox,
  MarkerIconContainer,
  AddressText,
  ButtonContainer,
  ContentBox,
} from "./order-type-bottom-sheet.styles";
import RadioButton from "../shared/RadioButton/RadioButton.component";
import Schedule from "./schedule.component";
import { useLocation, useNavigate } from "react-router-dom";

const OrderTypeBottomSheet = ({
  isOpen,
  toggleBottomSheet,
  onlineOrdersConfig,
  setOrderTypeError,
  tableNumber,
  orderType,
}) => {
  const getScheduledTime = useSelector(selectScheduledTime);

  const [selectedOption, setSelectedOption] = useState(
    getScheduledTime === "ASAP" ? "ASAP" : "schedule"
  );

  const getOrderType = orderType
    ? orderType
    : tableNumber && onlineOrdersConfig?.dineInEnabled
    ? "dinein"
    : "takeaway";

  const [currentOrderType, setCurrentOrderType] = useState(getOrderType);

  const [currentTableNumber, setCurrentTableNumber] = useState(
    tableNumber ? tableNumber : null
  );
  const [currentScheduledTime, setCurrentScheduledTime] =
    useState<any>(getScheduledTime);
  const kitchen = useSelector(selectKitchen);

  const headerModalBackground = getColor(
    "header-modal-background",
    onlineOrdersConfig
  );
  const backgroundSecondary = getColor(
    "background-secondary",
    onlineOrdersConfig
  );
  const brandTertiary = getColor("brand-tertiary", onlineOrdersConfig);
  const brandAccent = getColor("brand-accent", onlineOrdersConfig);
  const textBody = getColor("text-body", onlineOrdersConfig);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const orderWaitTime = onlineOrdersConfig?.orderReadyTime
    ? Number(onlineOrdersConfig?.orderReadyTime)
    : 15;

  const dispatch = useDispatch();
  let isMobile = useMediaQuery("(max-width: 768px)");

  const handleOptionChange = event => {
    if (event.target.value === "ASAP") {
      setCurrentScheduledTime("ASAP");
    } else {
      setCurrentScheduledTime(null);
    }
    setSelectedOption(event.target.value);
  };

  const handleTableNumberChange = event => {
    const { name, value } = event.target;

    setCurrentTableNumber(value);
  };

  const checkIsDisabled = () => {
    if (currentOrderType === "dinein") {
      if (currentTableNumber === null || currentTableNumber === "") {
        return true;
      }
      return false;
    }

    if (currentOrderType === "takeaway") {
      if (currentScheduledTime === null) {
        return true;
      }
      return false;
    }
  };

  // Function to update URL when table number changes
  const updateTableNumberInURL = newTableNumber => {
    const params = new URLSearchParams(window.location.search);
    if (newTableNumber) {
      params.set("tableNumber", newTableNumber);
    } else {
      params.delete("tableNumber");
    }
    navigate(`${pathname}?${params.toString()}`);
  };

  const handleSubmit = () => {
    if (currentOrderType === "dinein") {
      // @ts-ignore
      updateTableNumberInURL(currentTableNumber);

      if (setOrderTypeError) {
        setOrderTypeError(false);
      }
    }

    if (currentOrderType === "takeaway") {
      // @ts-ignore
      updateTableNumberInURL(null);
      if (currentScheduledTime?.time) {
        dispatch(
          setScheduledTime(
            `${currentScheduledTime.displayDate} at ${currentScheduledTime.time}`
          )
        );
      } else {
        dispatch(setScheduledTime(currentScheduledTime));
      }
    }
    toggleBottomSheet();
  };

  const handleOrderTypeChange = orderType => {
    setCurrentOrderType(orderType);
  };

  const [dayOffset, setDayOffset] = useState(0);
  const contentRef = useRef(null);
  const [drawerHeight, setDrawerHeight] = useState("auto");

  useEffect(() => {
    setTimeout(() => {
      if (contentRef.current) {
        if (selectedOption === "schedule" && currentOrderType === "takeaway") {
          setDrawerHeight(contentRef.current.scrollHeight + "px");
        } else {
          setDrawerHeight("auto");
        }
      }
    });
  }, [selectedOption, currentOrderType, dayOffset, isOpen]);

  return (
    <Drawer
      transitionDuration={{ enter: isMobile && 300, exit: isMobile && 300 }}
      open={isOpen}
      onClose={toggleBottomSheet}
      anchor="bottom"
      sx={{
        "& .MuiDrawer-paper": {
          background: headerModalBackground,
          position: !isMobile && "absolute",
          top: !isMobile && "50%",
          left: !isMobile && "50%",
          transform: !isMobile && "translate(-50%, -50%) !important",
          width: isMobile ? "100%" : "560px",
          height:
            selectedOption === "schedule" &&
            currentOrderType === "takeaway" &&
            isMobile
              ? "100vh"
              : drawerHeight,
          minHeight: isMobile ? "390px" : "436px",
          maxHeight: isMobile ? "100vh" : "94vh",
          borderRadius: !isMobile && "8px",
          borderTopLeftRadius:
            selectedOption !== "schedule" && isMobile && "8px",
          borderTopRightRadius:
            selectedOption !== "schedule" && isMobile && "8px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Container ref={contentRef}>
        <ContentBox>
          <Header headerModalBackground={headerModalBackground}>
            <HeaderText brandAccent={brandAccent}>Order Type</HeaderText>
            <IconContainer
              brandTertiary={brandTertiary}
              headerModalBackground={headerModalBackground}
              onClick={toggleBottomSheet}
            >
              <CloseIcon size={isMobile ? "middle" : "large"} />
            </IconContainer>
          </Header>

          <BoxItem>
            {onlineOrdersConfig?.takeAwayEnabled &&
            onlineOrdersConfig?.dineInEnabled ? (
              <OrderTypeButtonsContainer>
                <OrderTypeButtonGroup
                  orderTypes={[
                    { value: "takeaway", label: "Take Away" },
                    { value: "dinein", label: "Dine In" },
                  ]}
                  currentOrderType={currentOrderType}
                  onOrderTypeChange={handleOrderTypeChange}
                />
              </OrderTypeButtonsContainer>
            ) : (
              <OrderTypeButtonsContainer>
                {onlineOrdersConfig?.takeAwayEnabled && (
                  <OrderTypeButtonGroup
                    orderTypes={[
                      { value: "takeaway", label: "Take Away Only" },
                    ]}
                  />
                )}
                {onlineOrdersConfig?.dineInEnabled && (
                  <OrderTypeButtonGroup
                    orderTypes={[{ value: "dinein", label: "Dine In Only" }]}
                  />
                )}
              </OrderTypeButtonsContainer>
            )}
            {(currentOrderType === "takeaway" ||
              currentOrderType === "dinein") && (
              <AddressBox textBody={textBody}>
                <MarkerIconContainer>
                  <MarkerIcon />
                </MarkerIconContainer>
                <AddressText>{kitchen?.fullAddress}</AddressText>
              </AddressBox>
            )}

            {currentOrderType === "takeaway" && (
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ marginBottom: "20px", display: "flex" }}>
                    <RadioButton
                      name="asap"
                      value="ASAP"
                      label={`ASAP (Ready in ${orderWaitTime} minutes)`}
                      checked={selectedOption === "ASAP"}
                      onChange={handleOptionChange}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <RadioButton
                      name="schedule"
                      value="schedule"
                      label="Schedule For Later"
                      checked={selectedOption === "schedule"}
                      onChange={handleOptionChange}
                    />
                  </div>
                </div>

                {selectedOption === "schedule" && (
                  <Schedule
                    currentScheduledTime={currentScheduledTime}
                    setCurrentScheduledTime={setCurrentScheduledTime}
                    dayOffset={dayOffset}
                    setDayOffset={setDayOffset}
                  />
                )}
              </div>
            )}
            {currentOrderType === "dinein" && (
              <FormInput
                label="Table Number"
                placeholder="Enter table number"
                type="string"
                required
                onChange={handleTableNumberChange}
                name="tableNumber"
                value={currentTableNumber}
                autoFocusEnable
              />
            )}
          </BoxItem>
        </ContentBox>

        <ButtonContainer
          headerModalBackground={headerModalBackground}
          backgroundSecondary={backgroundSecondary}
        >
          <Button disabled={checkIsDisabled()} onClick={handleSubmit}>
            Confirm
          </Button>
        </ButtonContainer>
      </Container>
    </Drawer>
  );
};

export default OrderTypeBottomSheet;
