import React from 'react';
import { ButtonGroup, ButtonGroupContainer, ButtonStyled } from './orderTypeButtonGroup.styles';
import { useSelector } from 'react-redux';
import { selectOnlineOrdersConfigs } from '../../../store/kitchen/kitchen.selector';
import { getColor } from '../../../utils/utils.common';

interface ButtonGroupProps {
  orderTypes: { value: string; label: string }[];
  currentOrderType?: string;
  onOrderTypeChange?: (orderType: string) => void;
}

const OrderTypeButtonGroup: React.FC<ButtonGroupProps> = ({ orderTypes, currentOrderType, onOrderTypeChange }) => {
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

  return (
    <ButtonGroupContainer>
      <ButtonGroup
        backgroundSecondary={getColor("background-secondary", onlineOrdersConfig)}
        backgroundTertiary={getColor("background-tertiary", onlineOrdersConfig)}>
        {orderTypes.length === 1 ?
          <ButtonStyled
            brandPrimary={getColor("brand-primary", onlineOrdersConfig)}
            textBody={getColor("text-body", onlineOrdersConfig)}
            headerModalBackground={getColor("header-modal-background", onlineOrdersConfig)}
            oneButton
          >
            {orderTypes[0].label}
          </ButtonStyled>
          :
          orderTypes.map(({ value, label }) => (
            <ButtonStyled
              key={value}
              active={currentOrderType === value}
              onClick={() => onOrderTypeChange(value)}
              brandPrimary={getColor("brand-primary", onlineOrdersConfig)}
              textBody={getColor("text-body", onlineOrdersConfig)}
              headerModalBackground={getColor("header-modal-background", onlineOrdersConfig)}
            >
              {label}
            </ButtonStyled>
          ))}
      </ButtonGroup>
    </ButtonGroupContainer>
  );
};

export default OrderTypeButtonGroup