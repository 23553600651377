import styled from "styled-components";

interface NoteTitleProps {
  textBody: string;
}

interface TextAreaContainerProps {
  fieldBorder: string;
  fieldPlaceholderText: string;
  fieldBackground: string;
  brandPrimary: string;
  textBody: string;
}

export const NoteItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 6px;
`;

export const NoteContainer = styled.div``;

export const NoteTitle = styled.div<NoteTitleProps>`
  font-size: 16px;
  font-weight: 600;
  color: ${({ textBody }) => `${textBody}`};

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const NoteClear = styled.div``;

export const TextAreaContainer = styled.div<TextAreaContainerProps>`
  textarea {
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    height: 94px;
    resize: none;
    padding: 12px 14px;
    border-radius: 8px;
    background: ${({ fieldBackground }) => `${fieldBackground}`};
    color: ${({ textBody }) => `${textBody}`};
    border: 1.5px solid ${({ fieldBorder }) => `${fieldBorder}`};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: block;

    &:focus {
      border-color: ${({ brandPrimary }) => `${brandPrimary}`};
    }

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: ${({ fieldPlaceholderText }) => `${fieldPlaceholderText}`};
      font-weight: 400;
      font-size: 18px;
    }

    @media screen and (max-width: 768px) {
      font-size: 16px;

      &::placeholder {
        font-size: 16px;
      }
    }
  }
`;
