import { AnyAction } from "redux";
import { setCartItems, setCartKitchenId, setCustomerDetails, setOrderNote, setScheduledTime } from "./cart.action";
import { CartItem, CustomerDetails } from "./cart.types";

export type CartState = {
  cartItems: CartItem[];
  kitchenId: string;
  orderNote: string;
  scheduledTime: string;
  customerDetails: CustomerDetails;
};

export const CART_INITIAL_STATE: CartState = {
  cartItems: [],
  kitchenId: "",
  orderNote: null,
  scheduledTime: null,
  customerDetails: {
    customerEmail: null,
    customerName: null,
    customerMobile: null
  },
};

export const cartReducer = (
  state = CART_INITIAL_STATE,
  action: AnyAction
): CartState => {
  if (setCartItems.match(action)) {
    return {
      ...state,
      cartItems: action.payload,
      // kitchenId: 
    };
  }

  if (setCartKitchenId.match(action)){
    return {
      ...state,
      kitchenId: action.payload
    }
  }
  if (setScheduledTime.match(action)){
    return {
      ...state,
      scheduledTime: action.payload
    }
  }
  if(setOrderNote.match(action)){
    return{
      ...state,
      orderNote: action.payload
    }
  }
  if(setCustomerDetails.match(action)){
    return{
      ...state,
      customerDetails: action.payload
    }
  }
  return state;
};
