import { ButtonHTMLAttributes, FC } from "react";
import {
  BaseButton,
  CartButton,
  GoogleSignInButton,
  InvertedButton,
} from "./button.styles";
import Spinner from "../spinner/spinner.component";
import { useSelector } from "react-redux";
import { selectOnlineOrdersConfigs } from "../../store/kitchen/kitchen.selector";
import { getColor } from "../../utils/utils.common";

export enum BUTTON_TYPE_CLASSES {
  base = "base",
  google = "google-sign-in",
  inverted = "inverted",
  cart = "cart-button"
}

const getButton = (buttonType = BUTTON_TYPE_CLASSES.base): typeof BaseButton =>
  ({
    [BUTTON_TYPE_CLASSES.base]: BaseButton,
    [BUTTON_TYPE_CLASSES.google]: GoogleSignInButton,
    [BUTTON_TYPE_CLASSES.inverted]: InvertedButton,
    [BUTTON_TYPE_CLASSES.cart]: CartButton,
  }[buttonType]);

  export type ButtonProps = {
    buttonType?: BUTTON_TYPE_CLASSES;
    isLoading?: boolean;
    buttonBackground?: string;
    buttonTextColor?: string;
  } & ButtonHTMLAttributes<HTMLButtonElement>

const Button: FC<ButtonProps> = ({ children, buttonType, disabled, isLoading, buttonBackground, buttonTextColor, ...otherProps }) => {
  const CustomButton = getButton(buttonType);

  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

  const brandPrimary = getColor("brand-primary", onlineOrdersConfig);
  const primaryButtonText = getColor("primary-button-text", onlineOrdersConfig);


  return (
    <CustomButton disabled={disabled || isLoading} {...otherProps} buttonBackground={brandPrimary} buttonTextColor={primaryButtonText}>
      {isLoading ? <Spinner size={35} color='white'/> : children}
    </CustomButton>
  );
};

export default Button;
