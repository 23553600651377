import styled from "styled-components";

interface ButtonGroupProps {
  backgroundSecondary: string;
  backgroundTertiary: string;
}

interface ButtonStyledProps {
  active?: boolean;
  brandPrimary: string;
  textBody: string;
  headerModalBackground: string;
  oneButton?: boolean;
}

export const ButtonGroupContainer = styled.div`

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const ButtonGroup = styled.div<ButtonGroupProps>`
  background: ${({ backgroundSecondary }) => backgroundSecondary};
  outline: 1px solid ${({ backgroundTertiary }) => backgroundTertiary};
  border-radius: 98px;
  display: flex;
  align-items: center;
`;

export const ButtonStyled = styled.div<ButtonStyledProps>`
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width:  ${({ oneButton }) => (oneButton ? '100%' : "50%")};
  padding: 11px;
  text-align: center;
  cursor:  ${({ oneButton }) => (oneButton ? 'initial' : "pointer")};
  margin: 4px;
  font-size: 16px;
  text-wrap: nowrap;
  border-radius: 56px;
  outline: 1px solid
    ${({ active, brandPrimary }) => (active ? brandPrimary : "transparent")};
  color: ${({ active, brandPrimary, textBody }) =>
    active ? brandPrimary : textBody};
  background: ${({ active, headerModalBackground }) =>
    active ? headerModalBackground : "transparent"};
  font-weight: ${({ active }) => (active ? "700" : "600")};

  box-shadow: 0px 1px 3px 0px
      ${({ active }) => (active ? 'rgba(16, 24, 40, 0.1)' : "transparent")},
    0px 1px 2px 0px
      ${({ active }) => (active ? 'rgba(16, 24, 40, 0.06)' : "transparent")};

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 12px;
    border-radius: 66px;
    height: 40px;
  }
`;
