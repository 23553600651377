import moment from "moment";

export const businessOpenCheck = schedule => {
  const date = moment(); // Today
  const dow = date.isoWeekday(moment().day()).format("dddd"); // Name of the day today
  let isOpen = false;

  // eslint-disable-next-line array-callback-return
  schedule &&
    schedule?.map(obj => {
      // FIND TODAYS DAY IN THE BUSINESS' SCHEDULE
      if (dow === Object.keys(obj)[0]) {
        // console.log("FOUND DAY")
        if (
          moment(Object.values(obj)[0].from, "hh:mmA").isBefore(moment()) &&
          moment(Object.values(obj)[0].to, "hh:mmA").isAfter(moment())
        ) {
          isOpen = true;
          return isOpen;
        }
      }
    });
  return isOpen;
};

export const getClosingTimeIncrements = (schedule, waitTime) => {
  const date = moment(); // Today
  const dow = date.isoWeekday(moment().day()).format("dddd"); // Name of the day today
  let closingTime = null;
  let increments = [];
  const now = moment().add(waitTime, "minutes");

  // eslint-disable-next-line array-callback-return
  schedule.map(obj => {
    // FIND TODAYS DAY IN THE BUSINESS' SCHEDULE
    if (dow === Object.keys(obj)[0]) {
      // console.log("FOUND DAY")
      closingTime = moment(Object.values(obj)[0].to, "hh:mmA");
    }
  });

  // Calculate the difference in minutes between now and closing time
  const diffInMinutes = moment(closingTime).diff(now, "minutes");
  // Round down to the nearest 15-minute interval
  const roundedDiff = Math.ceil(diffInMinutes / waitTime) * waitTime;
  // Generate time increments starting from now, in 15-minute intervals
  for (let i = 0; i <= roundedDiff; i += waitTime) {
    const time = now.clone().add(i, "minutes");
    // console.log(time)
    // console.log(time.isBefore(closingTime))
    if (time.isBefore(closingTime)) {
      const minute = time.minute();
      const roundedMinute = Math.ceil(minute / 5) * 5;
      time.minute(roundedMinute);
      increments.push(time.format("hh:mmA"));
    }
  }

  return increments;
};

export const generateAvailableTimeSlots = (schedule, dayOffset, waitTime) => {
  const todayIndex = moment().isoWeekday();
  const targetIndex = (todayIndex + dayOffset) % 7;
  const dayName = moment().day(targetIndex).format("dddd");

  const daySchedule = schedule.find(obj => obj[dayName]);
  if (!daySchedule) return { increments: [], dayName, openingTime: null };

  let closingTime = moment(daySchedule[dayName].to, "hh:mmA");
  let openingTime = moment(daySchedule[dayName].from, "hh:mmA");
  const now = moment().add(waitTime, "minutes");

  if (dayOffset === 0) {
    openingTime = now.isAfter(openingTime) ? now.clone() : openingTime;
  }

  const minute = openingTime.minute();
  const roundedMinute = Math.ceil(minute / waitTime) * waitTime;
  openingTime.minute(roundedMinute).second(0);

  let increments = [];
  for (
    let time = openingTime.clone();
    time.isBefore(closingTime);
    time.add(waitTime, "minutes")
  ) {
    increments.push(time.format("hh:mmA"));
  }

  return { increments, dayName, openingTime: daySchedule[dayName].from };
};

export const getOpeningOrClosingTime = schedule => {
  // console.log(schedule)
  const todayName = moment().format("dddd");

  const now = moment();
  const daySchedule = schedule?.find(obj => obj[todayName]);

  if (!daySchedule) return "Today";

  const { from, to } = daySchedule[todayName];

  if (!from || !to) {
    return "";
  }

  const openingTime = moment(from, "hh:mmA");
  const closingTime = moment(to, "hh:mmA");

  if (!openingTime.isValid() || !closingTime.isValid()) {
    return "";
  }

  return now.isBetween(openingTime, closingTime)
    ? `${closingTime.format("hh:mmA")}`
    : `${openingTime.format("hh:mmA")}`;
};

//  Ne functions
export const findNextOpening = schedule => {
  const now = moment();
  const currentTime = now.format("hh:mmA");

  // Check next 7 days starting from today
  for (let i = 0; i < 7; i++) {
    const dayName = moment().add(i, "days").format("dddd");
    const daySchedule = schedule?.find(obj => Object.keys(obj)[0] === dayName) ?? [];

    if (daySchedule && daySchedule[dayName]) {
      const openingTimes = [];

      // Add first opening/closing time pair if they exist
      if (daySchedule[dayName].from && daySchedule[dayName].to) {
        openingTimes.push({
          open: daySchedule[dayName].from,
          close: daySchedule[dayName].to,
        });
      }

      // Add second opening/closing time pair if they exist
      if (daySchedule[dayName].from2 && daySchedule[dayName].to2) {
        openingTimes.push({
          open: daySchedule[dayName].from2,
          close: daySchedule[dayName].to2,
        });
      }

      // Skip if no opening times
      if (openingTimes.length === 0) continue;
  
      // Sort times chronologically
      openingTimes.sort((a, b) =>
        moment(a.open, "hh:mmA").diff(moment(b.open, "hh:mmA"))
      );

        for (let timeSlot of openingTimes) {
            
        // For today, only return future times
            if (i === 0) {
           
          if (
            moment(currentTime, "hh:mmA").isBetween(
              moment(timeSlot.open, "hh:mmA"),
              moment(timeSlot.close, "hh:mmA")
            )
          ) {
            return {
              day: dayName,
              time: timeSlot.open,
              closingTime: timeSlot.close,
            };
          }
        } else {
          // For future days, return the first opening time
          return {
            day: dayName,
            time: timeSlot.open,
            closingTime: timeSlot.close,
          };
        }
      }
    }
  }
  return null;
};
