import { FC, InputHTMLAttributes, useEffect, useRef } from "react";
import { FormInputLabel, Group, Input, ErrorText, InputContainer, ErrorIconContainer } from "./form-input.styles";
import { useSelector } from "react-redux";
import { selectOnlineOrdersConfigs } from "../../store/kitchen/kitchen.selector";
import { getColor, getFontName } from "../../utils/utils.common";
import { FieldErrorIcon } from "../icons";

type FormInputProps = { label: string, error?: string, autoFocusEnable?: boolean } & InputHTMLAttributes<HTMLInputElement>;

const FormInput: FC<FormInputProps> = ({ label, style, error, autoFocusEnable, ...otherProps }) => {
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

  const textBody = getColor("text-body", onlineOrdersConfig);
  const brandPrimary = getColor("brand-primary", onlineOrdersConfig);
  const fieldBorder = getColor("field-border", onlineOrdersConfig);
  const fieldBackground = getColor("field-background", onlineOrdersConfig);
  const fieldPlaceholderText = getColor("field-placeholder-text", onlineOrdersConfig);

  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocusEnable) {
      inputRef.current?.focus();
    }
  }, []);

  return (
    <Group style={{ ...style }}>
      {label && (
        <FormInputLabel textBody={textBody}>
          {label}
        </FormInputLabel>
      )}
      <InputContainer>
        <Input
          {...otherProps}
          brandPrimary={brandPrimary}
          fieldBorder={fieldBorder}
          fieldBackground={fieldBackground}
          fieldPlaceholderText={fieldPlaceholderText}
          textBody={textBody}
          error={error || ''}
          ref={inputRef}
        />
        {error &&
          <ErrorIconContainer>
            <FieldErrorIcon />
          </ErrorIconContainer>
        }
      </InputContainer>
      {error &&
        <ErrorText>
          {error}
        </ErrorText>
      }
    </Group>
  );
};

export default FormInput;
