import styled from "styled-components";
import { WhiteAndBlack } from "../../utils/colorContrast/colorContrast";

export const VariantsContainer = styled.div`
  border-bottom: 4px solid
    ${({ backgroundTertiary }) => `${backgroundTertiary}`};
  padding: 32px 24px;
  background: ${({ headerModalBackground }) => `${headerModalBackground}`};

  @media screen and (max-width: 768px) {
    padding: 24px 16px;
  }
`;

export const VariantHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 0px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: ${({ brandTertiary }) => `${brandTertiary}`};

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Subtitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: ${({ textBody }) => `${textBody}`};
  margin-top: 2px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
export const VarientCategoryContainer = styled.div`
  border-radius: 16px;
  padding: 8px 10px;
  min-width: 76px;
  text-align: center;

  &.required-varient {
    background: #fef3f2;
    color: #b42318;
  }

  &.optional-varient {
    background: #f0f9ff;
    color: #026aa2;
  }

  @media screen and (max-width: 768px) {
    padding: 5px 10px;
  }
`;
export const VarientCategoryText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ChoicesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ChoicesText = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${({ textBody }) => `${textBody}`};
  cursor: pointer;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const ExtraChargeText = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ textBody }) => `${textBody}`};
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  margin-top: 3px;

  input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    appearance: none;
    width: 24px;
    height: 24px;
    border: 1px solid ${({ textBody }) => textBody};
    background: ${({ backgroundSecondary }) => backgroundSecondary};
    cursor: pointer;
    transition: background 0.3s ease, border-color 0.3s ease;
    position: relative; /* Ensures centering works correctly */
    margin: 0;
    border-radius: 50%;

    /* Style for Checkbox */
    &[type="checkbox"] {
      border-radius: 6px;
    }

    &:checked {
      &::after {
        content: "";
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
      }

      /* Checkbox Checkmark */
      &[type="checkbox"]::after {
        width: 16.7px;
        height: 16.7px;
        background: ${({ themeColor }) => `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none"><path d="M12.6 1.8L4.9 9.5L1.4 6" stroke="${themeColor === WhiteAndBlack.black ? 'white' : 'black'}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat center `};
        background-size: auto;
        background-color: ${({ textBody }) => textBody};
        border-radius: 3px;
      }

      /* Radio Button Inner Circle */
      &[type="radio"]::after {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: ${({ textBody }) => textBody};
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      border-color: ${({ textBody }) => `${textBody}80`};
      background: ${({ backgroundSecondary }) => `${backgroundSecondary}80`};
      pointer-events: none;

      &:checked::after {
        background: ${({ textBody }) => `${textBody}80`};
      }
    }
  }
`;