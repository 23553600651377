import React from 'react';
import { ButtonActionStyled, ButtonIcon } from './buttonAction.styles';

interface ButtonActionProps {
  label: string;
  onClick: () => void;
  backgroundTertiary: string;
  brandPrimary: string;
  size?: 'small' | 'medium' | 'large';
  icon?: React.FC;
}

const ButtonAction: React.FC<ButtonActionProps> = ({ label, onClick, backgroundTertiary, brandPrimary, size = 'small', icon: Icon, }) => {

  return (
    <ButtonActionStyled onClick={onClick} backgroundTertiary={backgroundTertiary} brandPrimary={brandPrimary} size={size}>
      {Icon &&
        <ButtonIcon>
          <Icon />
        </ButtonIcon>
      }
      {label}
    </ButtonActionStyled>
  );
};

export default ButtonAction