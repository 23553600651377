import styled from "styled-components";

interface StyledRadioProps {
  textBody: string;
  backgroundSecondary: string;
}

interface LabelTextProps {
  textBody: string;
}

export const RadioWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const HiddenRadio = styled.input.attrs({ type: "radio" })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

export const StyledRadio = styled.div<StyledRadioProps>`
  width: 24px;
  height: 24px;
  border: 1px solid ${({ textBody }) => textBody};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px 12px 0 0;
  transition: border-color 0.2s ease-in-out;
  background-color: ${({ backgroundSecondary }) => backgroundSecondary};
  flex-shrink: 0;
  position: relative;

  ${HiddenRadio}:checked + & {
    background-color: transparent;
  }

  &::after {
    content: "";
    width: 16px;
    height: 16px;
    background-color: ${({ textBody }) => textBody};
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  ${HiddenRadio}:checked + &::after {
    opacity: 1;
  }
`;

export const LabelText = styled.span<LabelTextProps>`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;
  color: ${({ textBody }) => textBody};

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 1.4;
  }
`;
