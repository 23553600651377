export enum KITCHEN_ACTION_TYPES {
    FETCH_KITCHEN_START  = 'kitchen/FETCH_KITCHEN_START',
    FETCH_KITCHEN_SUCCESS    = 'kitchen/FETCH_KITCHEN_SUCCESS',
    FETCH_KITCHEN_FAILED = 'kitchen/FETCH_KITCHEN_FAILED',
    SET_ONLINE_ORDERS_CONFIGS = 'kitchen/SET_ONLINE_ORDERS_CONFIGS'
}
export type OnlineOrdersConfigs = {
    cardFeePercent: number;
    cardFeeFixedCharge: number;
    takeAwayEnabled: boolean;
    dineInEnabled: boolean;
    tyroLocationId: string;
    isTyroLocationIdValid: boolean;
    orderReadyTime: string;
    onlineOrderingPaused: boolean;
    bannerImageURL: string;
    logoURL: string;
    facebookURL: string;
    instagramURL: string;
    colorPalette: ColorPaletteType;
}

export type ColorPaletteType = {
    "brand-font-style": string;
    "header-modal-background": string;
    "card-background": string;
    "background-secondary": string;
    "text-body": string;
    "field-border": string;
    "field-placeholder-text": string;
    "brand-tertiary": string;
    "primary-button-text": string;
    "background-tertiary": string;
    "brand-accent": string;
    "background-primary": string;
    "secondary-font-style": string;
    "brand-primary": string;
    "field-background": string;
}

export type VariantSelected = {
    charge: string;
    extraCharge: boolean;
    id: string;
    option: string;
    optionCategory: string;
}

export type Dishes = {
    categoryId: any;
    id: string;
    category: string;
    imageUrl: string;
    dishDescription: string;
    dishName: string;
    dishPrice: number;
    dishPriceWithVariants: number;
    remoteUri: string;
    variantSelected: VariantSelected[];
    cartItemId: string;
    dishStatus: string;
}

export type Kitchen = {
    abn: any;
    email: any;
    phoneNumber: any;
    kitchenPushToken: any;
    kitchenId: any;
    tyro_location_id: any;
    orderWaitTime: any;
    hours: any;
    kitchenName: any;
    id: string;
    kitchenSlug: string;
    imageUrl: string;
    fullAddress: string;
    cuisine: string[];
    dishes: Dishes[];
    dishCategories: DishCategory[]
}

export type DishCategory = {
    name: string,
    id: string,
    categoryNumber: number
}

export type DishesMap = {
    [key: string]: Dishes[]
}
