import styled from "styled-components";
import { getFontSize } from "../../utils/utils.common";

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 20px;
  margin: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: 72px;
  background: ${({ headerModalBackground }) => headerModalBackground};
  border-bottom: 1px solid ${({ backgroundTertiary }) => backgroundTertiary};

  @media screen and (max-width: 768px) {
    height: 64px;
    padding: 7px 4px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  cursor: pointer;
  color: ${({ brandTertiary }) => brandTertiary};
  border-radius: 50%;
  align-self: center;
  padding: 10px;
  transition: all 0.2s ease;
  background: ${({ headerModalBackground }) => headerModalBackground};

  &:hover {
    filter: brightness(95%);
  }
`;

export const HeaderTitleBox = styled.div`
  img {
    width: 58px;
    height: 58px;
    display: block;
    object-fit: contain;
  }

  @media screen and (max-width: 768px) {
    img {
      width: 52px;
      height: 52px;
    }
  }
`;

export const HeaderTitle = styled.div`
  font-weight: 600;
  color: ${({ brandPrimary }) => brandPrimary};
  font-family: ${({ brandFontStyle }) => brandFontStyle};
  font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 30)};
  display: flex;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 20)};
  }
`;

export const MobileTitle = styled.div`
  font-weight: 600;
  font-family: ${({ brandFontStyle }) => brandFontStyle};
  font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 18)};
  color: ${({ brandAccent }) => brandAccent};
`;
