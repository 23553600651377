import styled from "styled-components";

export const CartIconConatiner = styled.div`
  padding: 20px;
  background: ${({ headerModalBackground }) => headerModalBackground};
  border-top: 1px solid ${({ backgroundTertiary }) => backgroundTertiary};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @media screen and (max-width: 768px) {
    padding: 12px 16px;
  }
`;
