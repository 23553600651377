import styled from "styled-components";
import { getFontSize } from "../../utils/utils.common";

export const FooterBg = styled.div`
  background: ${({ backgroundSecondary }) => backgroundSecondary};
  border-top: 1px solid ${({ backgroundTertiary }) => backgroundTertiary};
  padding: 24px 0;

  @media screen and (max-width: 768px) {
    padding: 20px 0;
  }
`;

export const StandardContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 892px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;

  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const FooterRestInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  .footer-rest-right {
    display: flex;
  }
`;

export const FooterRestName = styled.div`
  display: flex;
  flex-direction: column;
  font-family:  ${({ brandFontStyle }) => brandFontStyle};
  font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 18)};
  font-weight: 600;
  color: ${({ brandAccent }) => brandAccent};
  @media screen and (max-width: 768px) {
    font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 16)};
  }
`;

export const License = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ textBody }) => textBody};
`;

export const FooterInfoPowered = styled.div`
  display: flex;
  padding: 24px 0;
  font-size: 16px;
  font-weight: 600;
  color: ${({ textBody }) => textBody};

  @media screen and (max-width: 768px) {
    padding-bottom: 20px;

    span {
      font-size: 14px;
    }
  }

  img {
    display: block;
  }
`;

export const Divider = styled.div`
  display: 'flex';
  height: 1px;
  margin: 0 16px;
  background: ${({ backgroundTertiary }) => backgroundTertiary};
`;

export const FooterInfoSmall = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FooterInfoCopyright = styled.div`
  font-weight: 700;
  color: ${({ textBody }) => textBody};
  font-size: 14px;

  span {
    margin-left: 5px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 12px;
    font-size: 12px;
  }
`;

export const FooterInfoLinks = styled.div`
  font-weight: 700;
  color: ${({ brandTertiary }) => brandTertiary};
  font-size: 14px;

  a {
    margin-left: 24px;
    color: ${({ brandTertiary }) => brandTertiary};
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    a {
      margin: 0 24px 0 0;
    }
  }
`;
