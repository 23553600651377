
import { Fragment } from "react";
import { Outlet, Link, useLocation, useParams } from "react-router-dom";
// import { signOutUser } from '../../utils/firebase/firebase.utils'
import { NavigationContainer, IconContainer, HeaderTitleBox, HeaderTitle, MobileTitle } from './navigation.styles.jsx';
// import { useSelector } from "react-redux";
// import { selectCurrentUser } from "../../store/user/user.selector";
import pretzelLogo from "../../assets/pretzel-logo.png";
import { CloseIcon, LeftIcon } from "../../components/icons";
import { Box, useMediaQuery } from "@mui/material";
import { getColor, getFontName } from "../../utils/utils.common";
import { useSelector } from "react-redux";
import { selectOnlineOrdersConfigs } from "../../store/kitchen/kitchen.selector";

const Navigation = (props) => {
    const { pathname, search } = useLocation();
    const { kitchenSlug } = useParams();

    const isMobile = useMediaQuery('(max-width: 768px)');
    const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

    const headerModalBackground = getColor("header-modal-background", onlineOrdersConfig);
    const brandAccent = getColor("brand-accent", onlineOrdersConfig);
    const backgroundTertiary = getColor("background-tertiary", onlineOrdersConfig);
    const brandTertiary = getColor("brand-tertiary", onlineOrdersConfig);
    const brandFontStyle = getFontName("brand-font-style", onlineOrdersConfig);
    const brandPrimary = getColor("brand-primary", onlineOrdersConfig);

    const checkoutPath = `/${kitchenSlug}/checkout`;
    const paymentPath = `/${kitchenSlug}/checkout/payment`;
    const successPath = `/${kitchenSlug}/checkout/success`;


    const getMobileTitle = () => {
        if (pathname === checkoutPath) return "Your Cart";
        if (pathname === paymentPath) return "Payment";
        if (pathname === successPath) return "Order Confirmation";
        return "Default Title";
    };

    return (
      <Fragment>
        <NavigationContainer
          headerModalBackground={headerModalBackground}
          backgroundTertiary={backgroundTertiary}
        >
          <Box>
            {pathname === checkoutPath && (
              <Link to={`/${kitchenSlug}${search}`}>
                <IconContainer
                  brandTertiary={brandTertiary}
                  headerModalBackground={headerModalBackground}
                >
                                <CloseIcon size={isMobile ? "middle" : "large"} />
                </IconContainer>
              </Link>
            )}
            {pathname === paymentPath && (
              <Link to={`/${kitchenSlug}/checkout${search}`}>
                <IconContainer
                  brandTertiary={brandTertiary}
                  headerModalBackground={headerModalBackground}
                >
                  <LeftIcon size={isMobile ? "small" : "large"} />
                </IconContainer>
              </Link>
            )}
            {pathname === successPath && (
              <Link to={`/${kitchenSlug}`}>
                <IconContainer
                  brandTertiary={brandTertiary}
                  headerModalBackground={headerModalBackground}
                >
                  {isMobile ? (
                    <CloseIcon size="middle" />
                  ) : (
                    <LeftIcon size="large" />
                  )}
                </IconContainer>
              </Link>
            )}
            {(pathname === `/` || pathname === `/action`) && (
              <Link to={`/${kitchenSlug}`}>
                <img src={pretzelLogo} alt="logo" height={30} />
              </Link>
            )}
            {/* {currentUser ? (
                        <span className="nav-link" onClick={signOutUser}>SIGN OUT</span>
                        ) : (
                            <NavLink to='/auth'>
                            SIGN IN
                            </NavLink>
                        )} */}
            {/* <CartIcon /> */}
          </Box>
          <HeaderTitleBox>
            {isMobile ? (
              <MobileTitle
                brandFontStyle={brandFontStyle}
                brandAccent={brandAccent}
              >
                {getMobileTitle()}
              </MobileTitle>
            ) : props?.logoURL ? (
              <img src={props.logoURL} alt="" />
            ) : (
              <HeaderTitle
                brandPrimary={brandPrimary}
                brandFontStyle={brandFontStyle}
              >
                {props.title}
              </HeaderTitle>
            )}
          </HeaderTitleBox>
          <Box
            sx={{
              width: "52px",
              "@media screen and (max-width: 768px)": { width: "44px" },
            }}
          />
        </NavigationContainer>
        <Outlet />
      </Fragment>
    );
}

export default Navigation;
