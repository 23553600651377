import styled from "styled-components";

const isWhite = (color) => {
  if (!color) return false;
  const normalizedColor = color.trim().toLowerCase();

  return ["white", "#fff", "#ffffff", "rgb(255,255,255)"].includes(
    normalizedColor
  );
};

export const MenuItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px;
  flex: 1 1 0%;
  border-radius: 4px;
  cursor: pointer;
  height: 160px;
  border-radius: 8px;
  background: ${({ cardBackground }) => cardBackground};
  border: 1px solid
    ${({ cardBackground }) =>
      isWhite(cardBackground) ? "#eaecf0" : "transparent"};
  box-shadow: ${({ cardBackground }) =>
    isWhite(cardBackground)
      ? "0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
      : "none"};

  @media screen and (max-width: 768px) {
    height: 144px;
  }
`;
export const MainContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  height: 100%;
`;
export const ImageContainer = styled.div`
  flex: 1;
  width: 128px;
  height: 128px;
  display: flex;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;
export const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  border-radius: 4px;
  opacity: ${({ soldOut }) => (soldOut ? 0.6 : 1)};
`;

export const TextTopBox = styled.div`
  margin-right: ${({ imageUrl }) => (imageUrl ? "16px" : "0")};
`;

export const DescriptionContainer = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${({ textBody, soldOut }) => (soldOut ? `${textBody}80` : textBody)};
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1.5em * 2);

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const NameContainer = styled.span`
  font-size: 18px;
  font-weight: 600;

  color: ${({ brandTertiary, soldOut }) =>
    soldOut ? `${brandTertiary}80` : brandTertiary};

  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1.5em);

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const PriceContainer = styled.span`
  display: flex;
  align-self: end;
  font-size: 18px;
  font-weight: 600;
  color: ${({ textBody, soldOut }) => (soldOut ? `${textBody}80` : textBody)};
  line-height: 1;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const SoldOutContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  bottom: -3px;
`;

export const SoldOutCircle = styled.div`
  height: 4px;
  width: 4px;
  background: ${({ textBody }) => `${textBody}66`};
  margin: 0 8px;
  border-radius: 50%;
`;

export const SoldOutText = styled.div`
  display: flex;
  background: ${({ backgroundPrimary }) => backgroundPrimary};
  color: ${({ textBody }) => textBody};
  font-size: 14px;
  font-weight: 600;
  border-radius: 16px;
  line-height: 16px;
  padding: 4px 10px;
`;
