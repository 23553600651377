import React from "react";
import { Container, IconContainer, LabelContainer } from "./quantity.styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { getColor } from "../../../utils/utils.common";
import { selectOnlineOrdersConfigs } from "../../../store/kitchen/kitchen.selector";
import { useSelector } from "react-redux";

interface ButtonActionProps {
  label: number;
  decrement?: () => void;
  increment?: () => void;
  isRemoveDisabled: boolean;
  isAddDisabled: boolean;
  size?: 'small' | 'medium';
  mode?: 'standard' | 'action';
}

const Quantity: React.FC<ButtonActionProps> = ({
  label,
  decrement,
  increment,
  isRemoveDisabled = false,
  isAddDisabled = false,
  size = 'medium',
  mode = 'standard'
}) => {
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

  const backgroundTertiary = getColor(
    "background-tertiary",
    onlineOrdersConfig
  );

  const headerModalBackground = getColor(
    "header-modal-background",
    onlineOrdersConfig
  );

  const brandPrimary = getColor("brand-primary", onlineOrdersConfig);
  const textBody = getColor("text-body", onlineOrdersConfig);

  return (
    <Container mode={mode} backgroundTertiary={backgroundTertiary}>
      <IconContainer
        onClick={decrement} 
        size={size}
        mode={mode}
        disabled={isRemoveDisabled}
        backgroundTertiary={backgroundTertiary}
        brandPrimary={brandPrimary}
        headerModalBackground={headerModalBackground}
      >
        <RemoveIcon fontSize={size === 'medium' ? 'medium' : 'small'} />
      </IconContainer>
      <LabelContainer textBody={textBody}>{label}</LabelContainer>
      <IconContainer
        onClick={increment}
        size={size}
        mode={mode}
        disabled={isAddDisabled}
        backgroundTertiary={backgroundTertiary}
        brandPrimary={brandPrimary}
        headerModalBackground={headerModalBackground}
      >
        <AddIcon fontSize={size === 'medium' ? 'medium' : 'small'} />
      </IconContainer>
    </Container>
  );
};

export default Quantity;
