import { useSelector } from 'react-redux';
import CheckoutItem from '../../components/checkout-item/checkout-item.component';
import './checkout-confirmation.styles.jsx';
import { CheckoutContainer, OrderInfo, OrderInfoIcon, OrderInfoTextLarge, OrderInfoTextSmall, PaymentButtonContainer, PickupDetailsContainer, PickupDetailsItem, BoxError, Title } from './checkout-confirmation.styles.jsx';
import { BUTTON_TYPE_CLASSES } from '../../components/button/button.component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CheckoutDetails from '../../components/checkout-details/checkout-details.component';
import { selectOrderIsError } from '../../store/order/order.selector';
import { TextLarge, TextSmall, TotalContainer, TotalItem, TotalTextLg, TotalTextSm } from '../checkout/checkout.styles';
import { errorColor, successColor } from '../../constants';
import moment from 'moment';
import { getColor, getFontName } from '../../utils/utils.common';
import { Box, useMediaQuery } from '@mui/material';
import { selectOnlineOrdersConfigs } from '../../store/kitchen/kitchen.selector';
import { ErrorIcon, SuccessIcon } from '../../components/icons';
import PaymentButton from '../../components/payment-button/payment-button.component';

const CheckoutConfirmation = () => {
    const location = useLocation();
    const { order } = location.state || {};

    const isError = useSelector(selectOrderIsError);
    const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);
    const navigate = useNavigate();

    const headerModalBackground = getColor("header-modal-background", onlineOrdersConfig);
    const brandAccent = getColor("brand-accent", onlineOrdersConfig);
    const brandFontStyle = getFontName("brand-font-style", onlineOrdersConfig);
    const backgroundTertiary = getColor("background-tertiary", onlineOrdersConfig);

    const { kitchenSlug } = useParams();
    const isMobile = useMediaQuery('(max-width: 768px)');

    const getOrderType = order?.orderType ? order.orderType : null;
    const getTableNumber = order?.tableNumber ? order.tableNumber : null;
    const getScheduledTime = order?.scheduledPickupTime !== "ASAP" && order?.scheduledPickupTime !== undefined ? order.scheduledPickupTime : null

        const orderSubtotal =
          order?.cartItems?.reduce(
            (total, cartItem) =>
              total + cartItem.quantity * cartItem.dishPriceWithVariants,
            0
          ) ?? null;
    const orderTotal = order?.totalAmount ? order.totalAmount : 0;
    const cardTotalFee = order?.onlineCardFeeAmount ? order.onlineCardFeeAmount : 0;

    const shortOrderId = order?.orderId?.slice(-8).toUpperCase() ? order.orderId.slice(-8).toUpperCase() : null

    const userFriendlyId = order?.orderCount ? order.orderCount : shortOrderId;
    const orderWaitTime = order?.orderWaitTime ? order.orderWaitTime : 15;
    const kitchenAddress = order?.kitchenAddress ? order.kitchenAddress : "";
    const kitchenName = order?.kitchenName ? order.kitchenName : "";

    const customerEmail = order?.customerDetails?.customerEmail ? order.customerDetails.customerEmail : null

    let time;
    if (order?.createdAt) {

        time = moment(
            order?.createdAt
        ).add(orderWaitTime, "minute").format('hh:mm A');
    }

    // order not found if session id is wrong or not stored in DB
    if (isError instanceof Error) {
        return (
            <Box sx={{ backgroundColor: headerModalBackground, minHeight: 'calc(100vh - 72px)', "@media screen and (max-width: 768px)": { minHeight: 'calc(100vh - 64px)' }, }}>
                <CheckoutContainer>
                    {!isMobile && (
                        <Title brandFontStyle={brandFontStyle} brandAccent={brandAccent}>Order Confirmation</Title>
                    )}
                    <OrderInfo backgroundColor={errorColor}>
                        <OrderInfoIcon>
                            <ErrorIcon />
                        </OrderInfoIcon>
                        <OrderInfoTextLarge>
                            Oops, something went wrong.
                        </OrderInfoTextLarge>
                        <OrderInfoTextSmall>
                            Try Refresh the page and will try find your order. 
                        </OrderInfoTextSmall>
                    </OrderInfo>
                    <BoxError>
                        <div style={{ marginBottom: '10px' }}>
                            If you don't receive a confirmation email, ask at the counter or call the venue to see if your order has gone through.
                        </div>
                        <div>
                            If you receive a confirmation email, this means your order request has been sent.
                        </div>
                    </BoxError>
                    <div style={{ margin: '0px 16px' }}>
                        <PaymentButton
                            buttonType={BUTTON_TYPE_CLASSES.cart}
                            onClick={() => window.location.reload(true)}
                            style={{ maxWidth: '100%' }}
                            text="Refresh"
                        />
                    </div>

                </CheckoutContainer>
            </Box>
        )
    }

    return (
      <Box
        sx={{
          backgroundColor: headerModalBackground,
          minHeight: "calc(100vh - 72px)",
          "@media screen and (max-width: 768px)": {
            minHeight: "calc(100vh - 64px)",
          },
        }}
      >
        <CheckoutContainer>
          {!isMobile && (
            <Title brandFontStyle={brandFontStyle} brandAccent={brandAccent}>
              Order Confirmation
            </Title>
          )}
          <OrderInfo backgroundColor={successColor}>
            <OrderInfoIcon>
              <SuccessIcon />
            </OrderInfoIcon>
            <OrderInfoTextLarge>Order # {userFriendlyId}</OrderInfoTextLarge>
            <OrderInfoTextSmall>
              Confirmation has been sent to your email {customerEmail}
            </OrderInfoTextSmall>
          </OrderInfo>
          <PickupDetailsContainer backgroundTertiary={backgroundTertiary}>
            <PickupDetailsItem>
              {getOrderType === "Take Away" ? (
                <CheckoutDetails
                  title="Take Away"
                  body={`${
                    getScheduledTime === null
                      ? `Pick up ASAP in ${orderWaitTime} mins`
                      : `Pick Up ${getScheduledTime}`
                  }`}
                />
              ) : (
                <CheckoutDetails
                  title="Dine In"
                  body={`Table ${getTableNumber}`}
                />
              )}
            </PickupDetailsItem>
            <PickupDetailsItem>
              <div>
                <TextLarge>{kitchenName}</TextLarge>
                <TextSmall>{kitchenAddress}</TextSmall>
              </div>
            </PickupDetailsItem>
          </PickupDetailsContainer>
          {order?.cartItems?.map(orderItem => (
            <CheckoutItem key={orderItem.cartItemId} cartItem={orderItem} />
          ))}
          <TotalContainer>
            <TotalItem>
              <TotalTextSm>Subtotal</TotalTextSm>
              <TotalTextSm>${orderSubtotal.toFixed(2)}</TotalTextSm>
            </TotalItem>
            {cardTotalFee !== 0 && (
              <TotalItem>
                <TotalTextSm>Card Surcharge</TotalTextSm>
                <TotalTextSm>${Number(cardTotalFee)?.toFixed(2)}</TotalTextSm>
              </TotalItem>
            )}
            <TotalItem>
              <TotalTextLg>Total (Including GST)</TotalTextLg>
              <TotalTextLg>${orderTotal?.toFixed(2)}</TotalTextLg>
            </TotalItem>
          </TotalContainer>
          {isMobile && (
            <PaymentButtonContainer
              headerModalBackground={headerModalBackground}
              backgroundTertiary={backgroundTertiary}
            >
              <PaymentButton
                buttonType={BUTTON_TYPE_CLASSES.cart}
                onClick={() => navigate(`/${kitchenSlug}`)}
                style={{ maxWidth: "100%" }}
                text="Back to menu"
              />
            </PaymentButtonContainer>
          )}
        </CheckoutContainer>
      </Box>
    );
}

export default CheckoutConfirmation;
