import { useDispatch, useSelector } from 'react-redux';
import { clearItemFromCart, incrementItemInCart, removeItemFromCart } from '../../store/cart/cart.action';
import { selectCartItems } from '../../store/cart/cart.selector';
import './checkout-item.styles.jsx';
import { CheckoutItemContainer, HeaderText, PriceText, QuantityContainer, QuantityConfirmation, QuantityInfo, VariantText, CheckoutItemAction, HeaderItem } from './checkout-item.styles.jsx';
import { useLocation, useParams } from 'react-router-dom';
import Quantity from '../shared/quantity/quantity.component';
import { useState } from 'react';
import ButtonAction from '../shared/buttonAction/buttonAction.component';
import { getColor } from '../../utils/utils.common';
import { selectOnlineOrdersConfigs } from '../../store/kitchen/kitchen.selector';

const CheckoutItem = ({ cartItem }) => {
    const { pathname } = useLocation();
    const { dishName, dishPriceWithVariants, quantity } = cartItem;

    const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);
    const cartItems = useSelector(selectCartItems);
    const dispatch = useDispatch();

    const clearItemHandler = () => dispatch(clearItemFromCart(cartItems, cartItem));
    const addItemHandler = () => dispatch(incrementItemInCart(cartItems, cartItem));
    const removeItemHandler = () => dispatch(removeItemFromCart(cartItems, cartItem));

    const [showQuantity, setShowQuantity] = useState(false)

    const { kitchenSlug } = useParams();

    const backgroundTertiary = getColor("background-tertiary", onlineOrdersConfig);
    const brandTertiary = getColor("brand-tertiary", onlineOrdersConfig);

    return (
        <CheckoutItemContainer backgroundTertiary={backgroundTertiary}>
            {(pathname === `/${kitchenSlug}/checkout/success` || pathname === `/${kitchenSlug}/checkout/payment`) ? (
                <>
                    <HeaderItem>
                        <HeaderText>
                            {dishName}
                            <QuantityConfirmation backgroundTertiary={backgroundTertiary}>
                                x{quantity}
                            </QuantityConfirmation>
                        </HeaderText>
                        <PriceText>${(dishPriceWithVariants * quantity).toFixed(2)}</PriceText>
                    </HeaderItem>
                    {cartItem.variantSelected?.length !== 0 && (
                        cartItem.variantSelected?.map(variant => (
                            <div>
                                <VariantText>{variant.name} {variant.extraCharge ? `($${Number(variant.charge).toFixed(2)})` : ''}</VariantText>
                            </div>
                        ))
                    )}
                </>
            ) : (
                <>
                    <HeaderItem>
                        <HeaderText>{dishName}</HeaderText>
                        <PriceText>${(dishPriceWithVariants * quantity).toFixed(2)}</PriceText>
                    </HeaderItem>
                    {cartItem.variantSelected?.length !== 0 && (
                        cartItem.variantSelected?.map(variant => (
                            <div>
                                {/* <VariantText>{variant.optionCategory}: </VariantText> */}
                                <VariantText>{variant.name} {variant.extraCharge ? `($${Number(variant.charge).toFixed(2)})` : ''}</VariantText>
                            </div>
                        ))
                    )}
                </>
            )}
            {pathname === `/${kitchenSlug}/checkout` && <CheckoutItemAction>
                {pathname === `/${kitchenSlug}/checkout` && (
                    <QuantityContainer>
                        {showQuantity ?
                            <Quantity decrement={removeItemHandler} increment={addItemHandler} isRemoveDisabled={quantity === 1} label={quantity} size='small' mode='action' />
                            :
                            <QuantityInfo onClick={() => setShowQuantity(!showQuantity)} backgroundTertiary={backgroundTertiary} brandTertiary={brandTertiary}>
                                {quantity}x
                            </QuantityInfo>
                        }
                    </QuantityContainer>
                )}
                {pathname === `/${kitchenSlug}/checkout` && (
                    <ButtonAction
                        onClick={clearItemHandler}
                        label='Remove'
                        backgroundTertiary={backgroundTertiary}
                        brandPrimary={brandTertiary}
                    />

                )}
            </CheckoutItemAction>
            }
        </CheckoutItemContainer>
    )
}

export default CheckoutItem;