import React from 'react';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  size: 'large' | 'small';
}

const HamburgerIcon: React.FC<SvgProps> = ({ size, ...props }) => (
  <>
    {size === 'large' &&
      <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" stroke="currentColor">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 16h16M4 8h24M4 24h24"
        />
      </svg>

    }
    {size === 'small' &&
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" stroke="currentColor">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M3 12h12M3 6h18M3 18h18"
        />
      </svg>
    }
  </>

);

export default HamburgerIcon;