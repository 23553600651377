import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCartCount } from "../../store/cart/cart.selector";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
import { CartIconConatiner } from "./cart-button.styles.jsx";

const CartButton = ({
  kitchenSlug,
  text,
  onClick,
  backgroundTertiary,
  headerModalBackground,
  brandPrimary,
  primaryButtonText,
}) => {
  const navigate = useNavigate();

  const cartCount = useSelector(selectCartCount);

  const goToCheckoutHandler = () => {
    const currentParams = new URLSearchParams(window.location.search);

    navigate(`/${kitchenSlug}/checkout?${currentParams.toString()}`);
  };
  return (
    <CartIconConatiner
      onClick={onClick ? onClick : goToCheckoutHandler}
      backgroundTertiary={backgroundTertiary}
      headerModalBackground={headerModalBackground}
    >
      <Button
        buttonType={BUTTON_TYPE_CLASSES.cart}
        buttonBackground={brandPrimary}
        buttonTextColor={primaryButtonText}
      >
        {text ? text : `View Cart (${cartCount})`}
      </Button>
    </CartIconConatiner>
  );
};

export default CartButton;
