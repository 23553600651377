import { useMediaQuery } from "@mui/material";
import { TitleText, OrderTypeInfo, OrderTypeInfoTop, OrderTypeInfoBottom, TypeText, CircleIcon, CartCountBox, Title } from "./payment-details.styles";
import { Link, useLocation } from "react-router-dom";
import { getColor, getFontName } from "../../utils/utils.common";

const PaymentDetails = ({ kitchenSlug, getOrderType, getTableNumber, cartTotal, cartCount, getScheduledTime, onlineOrdersConfig, kitchen, customerDetails }: any) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const brandAccent = getColor("brand-accent", onlineOrdersConfig);
  const brandPrimary = getColor("brand-primary", onlineOrdersConfig);
  const backgroundTertiary = getColor("background-tertiary", onlineOrdersConfig);
  const backgroundSecondary = getColor("background-secondary", onlineOrdersConfig);
  const headerModalBackground = getColor("header-modal-background", onlineOrdersConfig);
  const textBody = getColor("text-body", onlineOrdersConfig);
  const brandFontStyle = getFontName("brand-font-style", onlineOrdersConfig);

    const { search } = useLocation();

  return (
    <>
      {!isMobile && (
        <Title brandFontStyle={brandFontStyle} brandAccent={brandAccent}>
          Payment
        </Title>
      )}
      <OrderTypeInfo
        backgroundTertiary={backgroundTertiary}
        backgroundSecondary={backgroundSecondary}
      >
        <OrderTypeInfoTop>
          <Link
            to={`/${kitchenSlug}/checkout${search}`}
            style={{ color: textBody }}
          >
            <TypeText>
              {getOrderType === "takeaway" ? "Take Away" : `Dine In`}
            </TypeText>
            <CircleIcon textBody={textBody} />
            <TypeText>${cartTotal}</TypeText>
          </Link>
          <Link to={`/${kitchenSlug}/checkout${search}`}>
            <CartCountBox
              headerModalBackground={headerModalBackground}
              brandPrimary={brandPrimary}
            >
              {cartCount === 1 ? `${cartCount} Item` : `${cartCount} Items`}
            </CartCountBox>
          </Link>
        </OrderTypeInfoTop>
        <OrderTypeInfoBottom>
          <Link
            to={`/${kitchenSlug}/checkout${search}`}
            style={{ color: textBody }}
          >
            {getOrderType === "takeaway" && (
              <>
                {`${
                  getScheduledTime === "ASAP"
                    ? `Pick up ASAP in ${onlineOrdersConfig?.orderReadyTime} mins from ${kitchen.kitchenName}`
                    : `Pick Up ${getScheduledTime} from ${kitchen.kitchenName}`
                }`}
              </>
            )}
            {getOrderType === "dinein" &&
              `Table ${getTableNumber} at ${kitchen.kitchenName}`}
          </Link>
        </OrderTypeInfoBottom>
      </OrderTypeInfo>
      <TitleText backgroundSecondary={backgroundSecondary}>
        Almost There {customerDetails?.customerName}!
      </TitleText>
    </>
  );
}

export default PaymentDetails
