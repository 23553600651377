export enum WhiteAndBlack {
  black = '#000000',
  white = '#FFFFFF',
}

export function getRelativeLuminance(color: string): number {
  const [r, g, b] = color.match(/\w\w/g)!.map(c => parseInt(c, 16) / 255)
    .map(c => (c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)));
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

export function getContrastRatio(luminance1: number, luminance2: number): number {
  const [lighter, darker] = [luminance1, luminance2].sort((a, b) => b - a);
  return (lighter + 0.05) / (darker + 0.05);
}

export function getTextColor(backgroundColor: string): string {
  if (!/^#([0-9A-F]{6})$/i.test(backgroundColor)) {
    throw new Error("Invalid color format. Use #RRGGBB.");
  }

  const backgroundLuminance = getRelativeLuminance(backgroundColor);
  const contrastWithBlack = getContrastRatio(backgroundLuminance, 0); // Black luminance is 0
  const contrastWithWhite = getContrastRatio(backgroundLuminance, 1); // White luminance is 1
  return contrastWithBlack > contrastWithWhite ? WhiteAndBlack.black : WhiteAndBlack.white;
}