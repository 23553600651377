import { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  addItemToCart,
  addKitchenIdToCart,
} from "../../store/cart/cart.action";
import { useDispatch, useSelector } from "react-redux";
import { selectCartItems } from "../../store/cart/cart.selector";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
import {
  getColor,
  getFontName,
  isOnlineOrderConfigValid,
} from "../../utils/utils.common";
import { MenuVariants } from "../menu-variants/menu-variants.component";
import { useEffect } from "react";
import {
  IconContainer,
  DishName,
  DishNameEllips,
  DescriptionText,
  TextBox,
  DishPrice,
  QuantityContainer,
  ModalContainer,
  ModalScrollContainer,
} from "./menu-item-modal.styles";
import {
  dishSoldout,
} from "../../constants";
import {
  selectOnlineOrdersConfigs,
} from "../../store/kitchen/kitchen.selector";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../utils/firebase/firebase.utils";
import Quantity from "../shared/quantity/quantity.component";
import Status from "../shared/status/status.component";
import { CloseIcon } from "../icons";
import Note from "../shared/note/note.component";
import { useMediaQuery } from "@mui/material";
import {
    businessOpenCheck,
  findNextOpening,
} from "../../utils/closedKitchenCheck";

export const TransitionsModal = ({
  product,
  showModal,
  toggleShowModal,
  kitchen,
}) => {
  const {
    dishName,
    dishDescription,
    dishPrice,
    remoteUri,
    variants,
    dishStatus,
  } = product;
  const [quantity, setQuantity] = useState(1);
  const [dishPriceWithVariants, setDishPriceWithVariants] = useState(
    Number(dishPrice)
  );
  const [variantSelected, setVariantSelected] = useState([]);
  const [minimumMandatoryVariants, setMinimumMandatoryVariants] = useState(0);
  const [minimumVariantsSelected, setMinimumVariantsSelected] = useState(0);
  const kitchenId = kitchen?.kitchenId;
  const kitchenTier = kitchen?.tier;

  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
    const isKitchenOpen = businessOpenCheck(kitchen?.hours?.schedule ?? null);
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

  const headerModalBackground = getColor(
    "header-modal-background",
    onlineOrdersConfig
  );
  const brandAccent = getColor("brand-accent", onlineOrdersConfig);
  const backgroundTertiary = getColor(
    "background-tertiary",
    onlineOrdersConfig
  );
  const textBody = getColor("text-body", onlineOrdersConfig);
  const brandTertiary = getColor("brand-tertiary", onlineOrdersConfig);
  const brandPrimary = getColor("brand-primary", onlineOrdersConfig);
  const backgroundSecondary = getColor(
    "background-secondary",
    onlineOrdersConfig
  );
  const brandFontStyle = getFontName("brand-font-style", onlineOrdersConfig);

  const [hasBorder, setHasBorder] = useState(false);

  const findNextOpeningTime = findNextOpening(kitchen?.hours?.schedule ?? null);

  const contentRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      const contentEl = contentRef.current;
      if (!contentEl) return;

      const handleScroll = () => {
        if (remoteUri) {
          setHasBorder(contentEl.scrollTop > 328);
        } else {
          setHasBorder(contentEl.scrollTop > 0);
        }
      };
      contentEl.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => contentEl.removeEventListener("scroll", handleScroll);
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  const isButtonDisabled = () => {
    if (minimumVariantsSelected >= minimumMandatoryVariants) {
      return false;
    }
    return true;
  };
  const addProductToCart = () => {
    dispatch(
      addItemToCart(
        cartItems,
        product,
        quantity,
        variantSelected,
        dishPrice,
        dishPriceWithVariants
      )
    );
    dispatch(addKitchenIdToCart(kitchenId));
    toggleShowModal();

    // log add to cart event
    logEvent(analytics, "add_to_cart", {
      kitchenName: kitchen?.kitchenName,
    });
  };

  const increment = () => {
    setQuantity(quantity + 1);
  };
  const decrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  useEffect(() => {
    let count = 0;
    // eslint-disable-next-line array-callback-return
    variants?.map(variant => {
      if (variant.mandatoryChoice) {
        count += variant.minCounter;
      }
    });
    setMinimumMandatoryVariants(count);
  });

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={showModal}
      onClose={toggleShowModal}
      closeAfterTransition
    >
      <Fade in={showModal}>
        <ModalContainer
          headerModalBackground={headerModalBackground}
          isMobile={isMobile}
        >
          <ModalScrollContainer ref={contentRef}>
            <Box style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Box sx={{ position: "sticky", right: 0, top: 0, zIndex: 13 }}>
                <IconContainer
                  onClick={toggleShowModal}
                  headerModalBackground={headerModalBackground}
                  brandPrimary={brandPrimary}
                >
                  <CloseIcon size={"middle"} />
                </IconContainer>
              </Box>
              {remoteUri && (
                <img
                  src={remoteUri}
                  alt=""
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "328px",
                    display: "block",
                  }}
                />
              )}
              <DishName
                hasBorder={hasBorder}
                brandFontStyle={brandFontStyle}
                brandAccent={brandAccent}
                headerModalBackground={headerModalBackground}
                backgroundTertiary={backgroundTertiary}
              >
                <DishNameEllips hasBorder={hasBorder}>
                  {dishName}
                </DishNameEllips>
              </DishName>
              <TextBox
                backgroundTertiary={backgroundTertiary}
                headerModalBackground={headerModalBackground}
              >
                {dishDescription && (
                  <DescriptionText textBody={textBody}>
                    {dishDescription?.replaceAll("\\n", "\n")}
                  </DescriptionText>
                )}
                <DishPrice textBody={textBody}>
                  ${Number(dishPrice).toFixed(2)}
                </DishPrice>
              </TextBox>

              <Box sx={{ paddingBottom: 2 }}>
                {product.variants?.map(
                  (
                    {
                      options,
                      variantName: optionCategory,
                      minCounter,
                      maxCounter,
                      mandatoryChoice,
                    },
                    index
                  ) => {
                    return (
                      <MenuVariants
                        key={index}
                        setDishPriceVariant={setDishPriceWithVariants}
                        setVariantSelected={setVariantSelected}
                        variantSelected={variantSelected}
                        dishPriceVariant={dishPriceWithVariants}
                        setMinimumVariantsSelected={setMinimumVariantsSelected}
                        maxCounter={maxCounter}
                        minCounter={minCounter}
                        optionCategory={optionCategory}
                        optionNumber={index}
                        options={options}
                        mandatoryChoice={mandatoryChoice}
                        kitchenTier={kitchenTier}
                        brandTertiary={brandTertiary}
                        textBody={textBody}
                        backgroundTertiary={backgroundTertiary}
                        headerModalBackground={headerModalBackground}
                        backgroundSecondary={backgroundSecondary}
                      />
                    );
                  }
                )}
              </Box>
              {isOnlineOrderConfigValid(onlineOrdersConfig) && (
                <Box
                  sx={{
                    padding: "16px 24px 32px",
                    "@media screen and (max-width: 768px)": {
                      padding: "8px 16px 32px",
                    },
                  }}
                >
                  <Note title="Item Note" buttonText="Add Item Note" />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: backgroundSecondary,
                position: "sticky",
                zIndex: 14,
                bottom: 0,
                left: 0,
                right: 0,
                background: headerModalBackground,
                padding: "20px 24px",
                minHeight: "89px",
                "@media screen and (max-width: 768px)": {
                  minHeight: "85px",
                  padding: "20px 16px",
                },
              }}
            >
              {isOnlineOrderConfigValid(onlineOrdersConfig) &&
                isKitchenOpen &&
                onlineOrdersConfig?.onlineOrderingPaused === false &&
                dishStatus !== dishSoldout && (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <QuantityContainer>
                        <Quantity
                          decrement={decrement}
                          increment={increment}
                          label={quantity}
                          isRemoveDisabled={quantity == 1}
                          size={isMobile ? "small" : "medium"}
                        />
                      </QuantityContainer>
                      <Button
                        disabled={isButtonDisabled()}
                        onClick={addProductToCart}
                        buttonType={BUTTON_TYPE_CLASSES.base}
                      >
                        Add to Cart ($
                        {(dishPriceWithVariants * quantity).toFixed(2)})
                      </Button>
                    </Box>
                  </>
                )}
              {isOnlineOrderConfigValid(onlineOrdersConfig) &&
                isKitchenOpen &&
                onlineOrdersConfig?.onlineOrderingPaused === false &&
                dishStatus === dishSoldout && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <QuantityContainer>
                      <Quantity
                        isRemoveDisabled={true}
                        isAddDisabled={true}
                        label={quantity}
                        size={isMobile ? "small" : "medium"}
                      />
                    </QuantityContainer>
                    <Button
                      disabled={true}
                      buttonType={BUTTON_TYPE_CLASSES.base}
                    >
                      {dishSoldout} (${dishPriceWithVariants})
                    </Button>
                  </Box>
                )}

              {isOnlineOrderConfigValid(onlineOrdersConfig) &&
                !isKitchenOpen && (
                  <Status
                    status="close"
                    primaryText="Closed"
                    secondaryText={`Opens ${findNextOpeningTime?.day} at ${findNextOpeningTime?.time}`}
                    forModalStatus={true}
                  />
                )}

              {isOnlineOrderConfigValid(onlineOrdersConfig) &&
                onlineOrdersConfig?.onlineOrderingPaused &&
                isKitchenOpen && (
                  <Status
                    status="notCurrently"
                    primaryText="Currently not accepting online orders."
                    hasBanner={!!onlineOrdersConfig?.bannerImageURL}
                  />
                )}

              {!isOnlineOrderConfigValid(onlineOrdersConfig) && (
                <Status
                  status="visual"
                  primaryText="Visual Menu Only"
                  forModalStatus={true}
                  statusCircle={false}
                />
              )}
            </Box>
          </ModalScrollContainer>
        </ModalContainer>
      </Fade>
    </Modal>
  );
};
