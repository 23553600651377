export const firebaseConfig = {
    apiKey: "AIzaSyDFfARCeYAuSuOg5iHZwEkQ2T2iCbKcLrc",
    authDomain: "homebite-practice-v2.firebaseapp.com",
    projectId: "homebite-practice-v2",
    storageBucket: "homebite-practice-v2.appspot.com",
    messagingSenderId: "351662589375",
    appId: "1:351662589375:web:8b4a19c6b81583a7d14d0a",
    measurementId: "G-M5WTB7J5W8",
  };
  
  export const googleMerchantId = ""
  export const tyroLiveMode = false
