import styled from "styled-components";

interface SpinnerOverlayProps {
  backgroundSecondary: string;
}

export const PaymentContainer = styled.div`
  display: flex;
  max-width: 526px;
  margin: 0 auto;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const PaymentFormContainer = styled.form``;

export const ParentContainer = styled.div<any>`
  visibility: ${({ overlayState }) => (overlayState ? "hidden" : "visible")};
  padding: 32px 16px 48px;

  @media screen and (max-width: 768px) {
    padding: 32px 16px 24px;
  }
`;

export const SpinnerOverlay = styled.div<SpinnerOverlayProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 240px;
  width: 100%;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
    height: calc(100% - 64px);
    background: ${({ backgroundSecondary }) => backgroundSecondary};
    border-radius: 8px;
  }
`;

export const SubmittingText = styled.div`
  position: relative;
  margin-top: 16px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
