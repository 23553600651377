import React from "react";
import { HiddenRadio, RadioWrapper, StyledRadio, LabelText } from "./RadioButton.styles";
import { useSelector } from "react-redux";
import { selectOnlineOrdersConfigs } from "../../../store/kitchen/kitchen.selector";
import { getColor } from "../../../utils/utils.common";

interface RadioButtonProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "id"> {
  label: string;
  id?: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({ label, id, ...props }) => {
  const autoId = id || `radio-${Math.random().toString(36).substr(2, 9)}`;

  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

  const textBody = getColor("text-body", onlineOrdersConfig);
  const backgroundSecondary = getColor("background-secondary", onlineOrdersConfig);

  return (
    <RadioWrapper htmlFor={autoId}>
      <HiddenRadio id={autoId} {...props} />
      <StyledRadio textBody={textBody} backgroundSecondary={backgroundSecondary} />
      <LabelText textBody={textBody}>{label}</LabelText>
    </RadioWrapper>

  );
};

export default RadioButton;