import { Dishes } from "../kitchen/kitchen.types";

export enum CART_ACTION_TYPES {
  SET_CART_ITEMS = "cart/SET_CART_ITEMS",
  SET_IS_CART_OPEN = "cart/SET_IS_CART_OPEN",
  SET_CART_COUNT = "cart/SET_CART_COUNT",
  SET_CART_TOTAL = "cart/SET_CART_TOTAL",
  SET_CART_KITCHEN_ID = "cart/SET_CART_KITCHEN_ID",
  SET_SCHEDULED_TIME = "cart/SET_SCHEDULED_TIME",
  SET_ORDER_NOTE = "cart/SET_ORDER_NOTE",
  SET_CUSTOMER_DETAILS = "cart/SET_CUSTOMER_DETAILS"
}

export type CartItem = Dishes & {
    quantity: number;
}

export type CustomerDetails = {
  customerName: string;
  customerEmail: string;
  customerMobile: string;
}
