import styled from "styled-components";
import { getFontSize } from "../../utils/utils.common";

export const ModalContainer = styled.div`
  position: ${({ isMobile }) => (isMobile ? "static" : "absolute")};
  top: 50%;
  left: 50%;
  transform: ${({ isMobile }) =>
    isMobile ? "inherit" : "translate(-50%, -50%)"};
  width: ${({ isMobile }) => (isMobile ? "100%" : "580px")};
  max-height: ${({ isMobile }) => (isMobile ? "100%" : "75%")};
  min-height: ${({ isMobile }) => (isMobile ? "100%" : "448px")};
  background: ${({ headerModalBackground }) => headerModalBackground};
  padding: 0;
  outline: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    overflow: hidden;
    border-radius: 12px;
  }
`;

export const ModalScrollContainer = styled.div`
  overflow-y: auto;
  flex: 1;
  min-height: 448px;
  display: flex;
  flex-direction: column;
`;

export const IconContainer = styled.div`
  display: flex;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 12;
  background: ${({ headerModalBackground }) => headerModalBackground};
  color: ${({ brandPrimary }) => brandPrimary};
  border-radius: 100%;
  cursor: pointer;
  padding: 10px;
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(95%);
  }
`;

export const DishName = styled.div`
  font-family: ${({ brandFontStyle }) => brandFontStyle};
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 12;
  font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 24)};
  font-weight: 600;
  padding: 19px 55px 19px 24px;
  background: ${({ headerModalBackground }) => `${headerModalBackground}`};
  color: ${({ brandAccent }) => `${brandAccent}`};

  border-bottom: ${({ hasBorder, backgroundTertiary }) =>
    hasBorder ? `1px solid ${backgroundTertiary}` : "1px solid transparent"};
  box-shadow: 0px 1px 3px 0px
      ${({ hasBorder }) =>
        hasBorder ? "rgba(16, 24, 40, 0.1)" : "transparent"},
    0px 1px 2px 0px
      ${({ hasBorder }) =>
        hasBorder ? "rgba(16, 24, 40, 0.06)" : "transparent"};

  @media screen and (max-width: 768px) {
    font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 20)};
    padding: 19px 55px 19px 16px;
  }
`;

export const DishNameEllips = styled.div`
  ${({ hasBorder }) =>
    hasBorder &&
    `
  display: -webkit-box; 
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`}
`;

export const TextBox = styled.div`
  padding: 0px 24px 30px;
  border-bottom: 4px solid
    ${({ backgroundTertiary }) => `${backgroundTertiary}`};
  background: ${({ headerModalBackground }) => `${headerModalBackground}`};

  @media screen and (max-width: 768px) {
    padding: 0 16px 24px;
  }
`;

export const DescriptionText = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: ${({ textBody }) => `${textBody}`};
  margin-bottom: 8px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 12px;
  }
`;

export const DishPrice = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: ${({ textBody }) => `${textBody}`};
  width: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const QuantityContainer = styled.div`
  margin-right: 32px;
`;
