import React from 'react';


const HashIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m7.917 2.5-2.5 15m9.166-15-2.5 15m5-10.833H2.917m13.333 6.666H2.083"
    />
  </svg>

);

export default HashIcon;