import styled from "styled-components";

export const CheckoutItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ backgroundTertiary }) => backgroundTertiary};
  padding: 20px 0;
  margin: 0 16px;
`;

export const HeaderItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const HeaderText = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const PriceText = styled(HeaderText)``;

export const VariantText = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const QuantityInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 3px 12px;
  background-color: ${({ backgroundTertiary }) => backgroundTertiary};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ brandTertiary }) => brandTertiary};
  cursor: pointer;
  height: 32px;
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(95%);
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const CheckoutItemAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  min-height: 40px;
`;

export const QuantityConfirmation = styled.span`
  background-color: ${({ backgroundTertiary }) => backgroundTertiary};
  border-radius: 4px;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  padding: 4px 6px;
  margin-left: 8px;
`;
