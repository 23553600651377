import styled from "styled-components";
import { getFontSize } from "../../utils/utils.common";

interface TitleProps {
  brandAccent: string;
  brandFontStyle: string;
}

export const StandardContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 892px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;

  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const Title = styled.div<TitleProps>`
  margin-bottom: 20px;
  font-family:  ${({ brandFontStyle }) => brandFontStyle};
  font-weight: 600;
  font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 24)};


  color: ${({ brandAccent }) => brandAccent};

  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
    font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 20)};
  }
`;

export const Preview = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 48px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 16px;
    margin-bottom: 40px;
  }
`;
