import styled from "styled-components";
import { getFontSize } from "../../utils/utils.common";

export const CheckoutContainer = styled.div`
  display: flex;
  max-width: 526px;
  margin: 0 auto;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    min-height: calc(100vh - 64px);
  }
`;

export const Title = styled.div`
  font-family: ${({ brandFontStyle }) => brandFontStyle};
  font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 24)};
  font-weight: 600;
  color: ${({ brandAccent }) => brandAccent};
  margin: 32px 16px 24px;
`;

export const OrderInfo = styled.div`
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  padding: 20px 16px;

  @media screen and (max-width: 768px) {
    padding: 16px;
    border-radius: 0px;
  }
`;

export const OrderInfoIcon = styled.div`
  display: flex;
  color: #fff;
  margin-bottom: 8px;
`;

export const OrderInfoTextLarge = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 4px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const OrderInfoTextSmall = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: #fff;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const PickupDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  margin-bottom: 4px;
  border-bottom: 4px solid ${({ backgroundTertiary }) => backgroundTertiary};
`;

export const PickupDetailsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
  padding: 0 16px 24px;

  @media screen and (max-width: 768px) {
    padding: 0px 16px 20px;
  }
`;

export const PaymentButtonContainer = styled.div`
  position: sticky;
  z-index: 14;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ headerModalBackground }) => headerModalBackground};
  border-top: 1px solid ${({ backgroundTertiary }) => backgroundTertiary};
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  padding: 20px 16px;

  @media screen and (max-width: 768px) {
    padding: 20px 16px;
    margin-top: auto;
  }
`;

export const BoxError = styled.div`
  padding: 24px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
