import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

* {
  -webkit-tap-highlight-color: transparent;
  margin: 0; 
  padding: 0; 
  box-sizing: border-box;
}

html {  
  height: 100%;
}

body {
  height: 100%;
  font-family: ${({ theme }) => theme.fontName}, sans-serif;
  line-height: 1.4;
  align-items: center;
  background-color: ${({ theme }) => theme.bgColor};
  color: ${({ theme }) => theme.textColor};

  font-size: ${({ fontName }) =>
    [
      "Bungee Shade",
      "Charm",
      "Pacifico",
      "Cherry Bomb One",
      "Poetsen One",
    ].includes(fontName)
      ? "120%"
      : "100%"};

  @media screen and (max-width: 900px){
    padding:0px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

button{
  font-family: ${({ theme }) => theme.fontName}, sans-serif;
}

textarea{
  font-family: ${({ theme }) => theme.fontName}, sans-serif;
}

input{
  font-family: ${({ theme }) => theme.fontName}, sans-serif;
}

label{
  font-family: ${({ theme }) => theme.fontName}, sans-serif;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

a {
  text-decoration: none;
  color: black;
}
`;
