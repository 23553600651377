import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Header, BodyText, KitchenName, ContentBox, HamburgerIconContainer, PoweredByBox, PoweredByText } from "./side-drawer.styles";
import { Link, ListItemButton } from "@mui/material";
import { useSelector } from "react-redux";
import { selectOnlineOrdersConfigs } from "../../store/kitchen/kitchen.selector";
import { getColor, getFontName, useMediaQuery } from "../../utils/utils.common";
import pretzelLogo from "../../assets/pretzel-logo.png";
import pretzelLogoWhite from "../../assets/pretzel-logo-white.png";
import { CloseIcon, FacebookIcon, HamburgerIcon, InstagramIcon } from "../icons";
import { useTextColor } from "../../utils/colorContrast/useColorContrast";
import { WhiteAndBlack } from "../../utils/colorContrast/colorContrast";


export const SideDrawer = ({ kitchen, setCheckDrawerState }) => {
  const [clicked, setClicked] = React.useState(false);

  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const brandTertiary = getColor("brand-tertiary", onlineOrdersConfig);
  const textBody = getColor("text-body", onlineOrdersConfig);
  const brandPrimary = getColor("brand-primary", onlineOrdersConfig);
  const headerModalBackground = getColor("header-modal-background", onlineOrdersConfig);
  const backgroundTertiary = getColor("background-tertiary", onlineOrdersConfig);
  const brandFontStyle = getFontName("brand-font-style", onlineOrdersConfig);

  const logoColor = useTextColor(headerModalBackground);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setCheckDrawerState(open)
      setClicked(open);
    };

  const list = () => (
    <Box sx={{
      display: "flex",
      flexDirection: 'column',
      minHeight: '100vh',
      background: headerModalBackground,

    }}>
      <Box sx={{
        minHeight: 'auto',
        display: "flex",
        flexDirection: 'column',
        flexGrow: '2',
        flexFlow: 'column',
      }}>
        <Box
          sx={{
            justifyContent: onlineOrdersConfig?.logoURL ? "center" : "space-between",
            display: "flex",
            alignItems: "center",
            padding: '12px 24px',
            minHeight: '76px',

            '@media (max-width:768px)': {
              padding: '6px 20px',
              minHeight: '64px',
            },
          }}
        >
          <Box>
            {onlineOrdersConfig?.logoURL ? <img src={onlineOrdersConfig.logoURL} style={{ width: '52px', height: '52px', objectFit: 'contain', display: 'block' }} alt="" />
              :
              <KitchenName brandPrimary={brandPrimary} brandFontStyle={brandFontStyle}>
                {kitchen?.kitchenName}
              </KitchenName>
            }
          </Box>
          <Box>
            <ListItemButton disableTouchRipple sx={{
              padding: '10px',
              borderRadius: '100%',
              position: 'absolute',
              right: '8px',
              top: '12px',
              transition: 'all 0.2s ease',
              color: brandTertiary,
              background: headerModalBackground,
              '&:hover': { filter: 'brightness(95%)', background: headerModalBackground },
            }} onClick={toggleDrawer(false)}>
              <CloseIcon size={isMobile ? 'middle' : 'large'} />
            </ListItemButton>
          </Box>
        </Box>
        <Box
          role="presentation"
          onKeyDown={toggleDrawer(false)}
          sx={{
            minHeight: 'auto',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '2',
            flexFlow: 'column',
          }}
        >
          <ContentBox>
            <Header color={brandTertiary} brandFontStyle={brandFontStyle}>Location</Header>
            <BodyText textBody={textBody}>{kitchen?.fullAddress}</BodyText>
          </ContentBox>
          {kitchen?.phoneNumber && (
            <ContentBox>
              <Header color={brandTertiary} brandFontStyle={brandFontStyle}>Mobile</Header>
              <BodyText textBody={textBody}>
                <a href={`tel:${kitchen?.phoneNumber}`} style={{ color: textBody }}>
                  {" "}
                  {kitchen?.phoneNumber}{" "}
                </a>
              </BodyText>
            </ContentBox>
          )}
          {kitchen?.email && (
            <ContentBox>
              <Header color={brandTertiary} brandFontStyle={brandFontStyle}>Email</Header>
              <BodyText textBody={textBody}>{kitchen?.email}</BodyText>
            </ContentBox>
          )}
          <ContentBox>
            <Header color={brandTertiary} brandFontStyle={brandFontStyle}>Opening Hours</Header>
            {kitchen?.hours?.schedule?.map((obj: unknown) => {
              return (
                <Box
                  sx={{
                    justifyContent: "space-between",
                    alignContent: "space-between",
                    display: "flex",
                    paddingTop: "12px",
                  }}
                >
                  <Box>
                    <BodyText textBody={textBody}>{Object.keys(obj)}</BodyText>
                  </Box>
                  <Box>
                    {Object.values(obj)[0].from !== "" ? (
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: "flex" }}>
                          <BodyText textBody={textBody}>{Object.values(obj)[0].from}</BodyText>
                          <BodyText textBody={textBody}>&nbsp;-&nbsp;</BodyText>
                          <BodyText textBody={textBody}>{Object.values(obj)[0].to}</BodyText>
                        </Box>
                        {(Object.values(obj)[0].from2 !== "" && Object.values(obj)[0].from2 !== undefined) && (
                          <Box sx={{ display: "flex" }}>
                            <BodyText textBody={textBody}>{Object.values(obj)[0].from2}</BodyText>
                            <BodyText textBody={textBody}>&nbsp;-&nbsp;</BodyText>
                            <BodyText textBody={textBody}>{Object.values(obj)[0].to2}</BodyText>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <BodyText textBody={textBody}>Closed</BodyText>
                    )}
                  </Box>
                </Box>
              );
            })}
          </ContentBox>
        </Box>
        <Box sx={{ justifyContent: "center", display: "flex", margin: "20px 0 40px" }}>
          {!!onlineOrdersConfig?.facebookURL &&
            <Link href={onlineOrdersConfig.facebookURL} target="_blank" rel="noopener noreferrer" sx={{ display: 'inline-flex', color: textBody, mx: 2, '&:hover': { color: brandTertiary, }, }}>
              <FacebookIcon size={isMobile ? 'small' : 'large'} />
            </Link>
          }
          {!!onlineOrdersConfig?.instagramURL &&
            <Link href={onlineOrdersConfig.instagramURL} target="_blank" rel="noopener noreferrer" sx={{ display: 'inline-flex', color: textBody, mx: 2, '&:hover': { color: brandTertiary, }, }}>
              <InstagramIcon size={isMobile ? 'small' : 'large'} />
            </Link>
          }
        </Box>
        <PoweredByBox headerModalBackground={headerModalBackground} backgroundTertiary={backgroundTertiary}>
          <PoweredByText textBody={textBody}>Powered by</PoweredByText>
          {<img src={logoColor === WhiteAndBlack.black ? pretzelLogo : pretzelLogoWhite} alt="Pretzel logo" width="57.6px" />}
        </PoweredByBox>
      </Box>
    </Box>
  );

  return (
    <React.Fragment>
      <HamburgerIconContainer onClick={toggleDrawer(true)} brandTertiary={brandTertiary} headerModalBackground={headerModalBackground}>
        <HamburgerIcon size={isMobile ? 'small' : 'large'} />
      </HamburgerIconContainer>
      <Drawer anchor="left" open={clicked} onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '100%',
            '@media (min-width: 768px)': {
              width: 500,
            },
          },
        }}
        transitionDuration={{ enter: 450, exit: 300 }}
      >
        {list()}
      </Drawer>
    </React.Fragment>
  );
};
