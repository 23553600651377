import styled from "styled-components";
import { fieldError } from "../../constants";

export const CheckoutDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const BodyText = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Total = styled(Title)`
  font-weight: 500;
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${fieldError};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const ErrorText = styled.div`
  margin-right: 4px;
`;
