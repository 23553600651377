import React from "react";

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  size: "large" | "small";
}

const LeftIcon: React.FC<SvgProps> = ({ size, ...props }) => (
  <>
    {size === "large" && (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
      >
        <path
          d="M25.3337 15.9998H6.66699M6.66699 15.9998L16.0003 25.3332M6.66699 15.9998L16.0003 6.6665"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {size === "small" && (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
      >
        <path
          d="M19 12H5M5 12L12 19M5 12L12 5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
  </>
);

export default LeftIcon;
