import React from 'react';


const MarkerIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 10.417a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 18.333c1.667-3.333 6.667-5.484 6.667-10a6.667 6.667 0 0 0-13.334 0c0 4.516 5 6.667 6.667 10Z"
    />
  </svg>
);

export default MarkerIcon;