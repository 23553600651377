import styled from "styled-components";
import { getFontSize } from "../../utils/utils.common";

export const CheckoutContainer = styled.div`
  max-width: 526px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 32px 0 0;

  @media screen and (max-width: 768px) {
  padding: 0 0 24px;
    }
`;

export const Title = styled.div`
  font-family: ${({ brandFontStyle }) => brandFontStyle};
  font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 24)};
  font-weight: 600;
  color: ${({ brandAccent }) => brandAccent};
  margin-bottom: 24px;
  padding: 0 16px;
`;

export const PickupDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  margin-bottom: 16px;
  border-bottom: 4px solid ${({ backgroundTertiary }) => backgroundTertiary};

  @media screen and (max-width: 768px) {
    padding-top: 24px;
    margin-bottom: 4px;
  }
`;

export const PickupDetailsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
  padding: 0 16px 32px;

  @media screen and (max-width: 768px) {
    padding: 0px 16px 24px;
  }
`;

export const TextLarge = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const TextSmall = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px 32px;

  @media screen and (max-width: 768px) {
    padding: 20px 16px 24px;
  }
`;

export const TotalItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TotalTextSm = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const TotalTextLg = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const DetailsTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 32px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
  }
`;

export const BottomContainer = styled.div`
  padding: 20px;
  background: ${({ headerModalBackground }) => headerModalBackground};
  border-top: 1px solid ${({ backgroundTertiary }) => backgroundTertiary};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @media screen and (max-width: 768px) {
    padding: 12px 16px;
  }
`;

export const EmptyCartText = styled.span`
  font-weight: 500;
  font-size: 23px;
  line-height: 140%;
`;

export const EmptyCartSubtitleText = styled(EmptyCartText)`
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  padding-top: 23px;
  padding-bottom: 24px;
`;

