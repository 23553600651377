import React, { useEffect, useRef, useState } from 'react';
import { NoteContainer, NoteItem, NoteTitle, NoteClear, TextAreaContainer } from './note.styles';
import ButtonAction from '../buttonAction/buttonAction.component';
import { RemoveIcon } from '../../icons';
import { getColor } from '../../../utils/utils.common';
import { useSelector } from 'react-redux';
import { selectOnlineOrdersConfigs } from '../../../store/kitchen/kitchen.selector';

interface NoteProps {
  title: string;
  buttonText: string;
  size?: 'large' | 'medium';
  defaultValue?: string;
  onBlurAction?: (value: string) => void;
  onClearAction?: () => void;
}

const Note: React.FC<NoteProps> = ({ title, buttonText, size = 'large', defaultValue = '', onBlurAction, onClearAction }) => {
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);
  const [showNote, setShowNote] = useState(true);
  const textareaRef = useRef(null);

  const fieldBorder = getColor("field-border", onlineOrdersConfig);
  const fieldPlaceholderText = getColor("field-placeholder-text", onlineOrdersConfig);
  const fieldBackground = getColor("field-background", onlineOrdersConfig);
  const backgroundTertiary = getColor("background-tertiary", onlineOrdersConfig);
  const brandPrimary = getColor("brand-primary", onlineOrdersConfig);
  const textBody = getColor("text-body", onlineOrdersConfig);

  useEffect(() => {
    if (!showNote && textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [showNote]);

  const onShowNote = () => {
    setShowNote(!showNote);
  };

  const onClear = () => {
    setShowNote(!showNote);
    onClearAction();
  }

  const onBlur = (e: any) => {
    const value = e.target?.value.trim();
    if (value && value !== null) {
      onBlurAction(value);
    }
  }

  return (
    <>
      {showNote &&
        <ButtonAction
          label={buttonText}
          onClick={onShowNote}
          backgroundTertiary={backgroundTertiary}
          brandPrimary={brandPrimary}
          size={size}
        />
      }

      {!showNote &&
        <NoteContainer>
          <NoteItem>
            <NoteTitle textBody={textBody}>{title}</NoteTitle>
            <NoteClear>
              <ButtonAction
                label='Clear'
                onClick={onClear}
                backgroundTertiary={backgroundTertiary}
                brandPrimary={brandPrimary}
                icon={RemoveIcon}
              />
            </NoteClear>
          </NoteItem>

          <TextAreaContainer fieldBorder={fieldBorder} fieldPlaceholderText={fieldPlaceholderText} fieldBackground={fieldBackground} brandPrimary={brandPrimary} textBody={textBody}>
            <textarea
              defaultValue={defaultValue}
              onBlur={onBlur}
              ref={textareaRef}
              placeholder={`Enter an ${title.toLowerCase()}...`}
            />
          </TextAreaContainer>
        </NoteContainer>
      }
    </>
  );
};

export default Note