import { createRef, useEffect, useMemo, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  selectKitchen,
  selectOnlineOrdersConfigs,
} from "../../store/kitchen/kitchen.selector";
import {
  selectCartCount,
  selectCartItems,
  selectCartTotal,
  selectCustomerDetails,
  selectOrderNote,
  selectScheduledTime,
} from "../../store/cart/cart.selector";
import {
  analytics,
  createOnlineOrder,
  functions,
  updateOnlineOrder,
} from "../../utils/firebase/firebase.utils";
import { logEvent } from "firebase/analytics";
import {
  CheckoutContainer,
  EmptyCartSubtitleText,
  EmptyCartText,
} from "../checkout/checkout.styles";
import Spinner from "../../components/spinner/spinner.component";
import { httpsCallable } from "firebase/functions";
import {
  ParentContainer,
  PaymentContainer,
  PaymentFormContainer,
  SpinnerOverlay,
  SubmittingText,
} from "./payment-form.styles";
import PaymentButton from "../../components/payment-button/payment-button.component";
import { BUTTON_TYPE_CLASSES } from "../../components/button/button.component";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearCart,
  setOrderNote,
  setScheduledTime,
} from "../../store/cart/cart.action";
import EmptyCartImage from "../../assets/empty-cart.png";
import {
  googleMerchantId,
  tyroLiveMode,
} from "../../utils/firebase/configKeys";
import {
  getColor,
  getFirstWordAndFirstLetterOfSecondWord,
  getFontName,
} from "../../utils/utils.common";
import { roundedUp } from "../../utils/roundUpNumber";
import { Order } from "../../store/order/order.types";
import PaymentDetails from "./payment-details.component";
import { fieldError } from "../../constants";

const tyroJSRef = createRef<any>();
const Payment = () => {
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);

  const secondaryFontStyle = getFontName(
    "secondary-font-style",
    onlineOrdersConfig
  );
  const backgroundSecondary = getColor(
    "background-secondary",
    onlineOrdersConfig
  );
  const headerModalBackground = getColor(
    "header-modal-background",
    onlineOrdersConfig
  );
  const textBody = getColor("text-body", onlineOrdersConfig);
  const brandPrimary = getColor("brand-primary", onlineOrdersConfig);
  const fieldBorder = getColor("field-border", onlineOrdersConfig);
  const fieldBackground = getColor("field-background", onlineOrdersConfig);

  const defaultSettings = useMemo(
    () => ({
      autoPaySecret: true,
      paySecret: "",
      theme: "default",
      styleProps: {
        fontFamily: secondaryFontStyle,
        bodyPadding: "0px",
        bodyBackgroundColor: headerModalBackground,
        inputBackgroundColor: fieldBackground,
        inputBorderColor: fieldBorder,
        inputBorderSize: "1.5px",
        inputBorderRadius: "8px",
        inputFontSize: "16px",
        inputFontColor: textBody,
        inputFontWeight: "400",
        inputPadding: "11px 10px",
        inputSpacing: "16px",
        inputErrorFontColor: fieldError,
        inputErrorBorderColor: fieldError,
        inputErrorBorderSize: "1.5px",
        // inputFocusFontColor: "#344054",
        inputFocusBorderColor: brandPrimary,
        inputFocusBorderSize: "1.5px",
        inputFocusBackgroundColor: fieldBackground,
        labelFontColor: textBody,
        labelFontSize: "16px",
        labelFontWeight: "600",
        labelPadding: "0px 0px 6px 0px",
        labelPosition: "block",
        errorFontColor: fieldError,
        errorFontSize: "14px",
        errorFontWeight: "400",
        errorBackgroundColor: fieldBackground,
        errorPadding: "0px",
        walletPaymentsButtonsHeight: "48px",
        walletPaymentsButtonsMargin: "0px",
        walletPaymentsDividerEnabled: true,
        walletPaymentsButtonsStacking: "VERTICAL",
        showSupportedCards: false,
        showCardIcon: false,
        googlePayButton: {
          buttonType: "pay",
        },
        applePayButton: {
          buttonStyle: "black",
        },
      },
      options: {
        applePay: {
          enabled: true,
          totalLabel: "Swifti",
        },
        googlePay: {
          enabled: true,
          merchantInfo: {
            merchantName: "Swifti",
            merchantId: googleMerchantId,
          },
        },
      },
    }),
    []
  );

  const [configuration, setConfiguration] = useState(() => defaultSettings);
  const [error, setError] = useState<{
    type: string;
    message: string;
    errorCode: number;
    debug: any;
  }>({ type: null, message: null, errorCode: null, debug: null });
  const [loading, setLoading] = useState(true);
  const [libraryReady, setLibraryReady] = useState(false);
  const [fetchingPaySecret, setFetchingPaySecret] = useState(false);
  const [tyroPayRequestId, setTyroPayRequestId] = useState(null);
  const [orderId, setOrderId] = useState(null);

  const [isFormLoading, setIsFormLoading] = useState(true);

  const [payRequestReady, setPayRequestReady] = useState(false);
  const [payFormReady, setPayFormReady] = useState(false);
  const [submittingOverlay, setSubmittingOverlay] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const cartCount = useSelector(selectCartCount);

  // const [formFields, setFormFields] = useState(defaultFormFields);
  // const { name, email, mobile } = formFields;

  const kitchen = useSelector(selectKitchen);
  const cartItems = useSelector(selectCartItems);
  const cartSubtotal = useSelector(selectCartTotal);
  // const cardFee = (cartSubtotal * 0.0175).toFixed(2);
  const customerDetails = useSelector(selectCustomerDetails);

  const getScheduledTime = useSelector(selectScheduledTime);
  const orderNote = useSelector(selectOrderNote);

  const searchParams = new URLSearchParams(window.location.search);

  const tableNumber = searchParams.get("tableNumber");

  const getOrderType =
    (tableNumber === "" || tableNumber) && onlineOrdersConfig?.dineInEnabled
      ? "dinein"
      : "takeaway";

  const cardFeePercent = onlineOrdersConfig?.cardFeePercent
    ? onlineOrdersConfig?.cardFeePercent / 100
    : 0;
  const cardFeeFixedCharge = onlineOrdersConfig?.cardFeeFixedCharge
    ? onlineOrdersConfig?.cardFeeFixedCharge / 100
    : 0;

  const cardTotalFee = roundedUp(
    cartSubtotal * cardFeePercent + cardFeeFixedCharge
  );
  const cartTotal = roundedUp(cartSubtotal + cardTotalFee);

  let totalQuantity =
    cartItems && cartItems.length > 0
      ? cartItems.reduce((acc, cartItem) => acc + cartItem.quantity, 0)
      : 0;

  const navigate = useNavigate();
  const { kitchenSlug } = useParams();

  const dispatch = useDispatch();

  // log payment page visited
  useEffect(() => {
    logEvent(analytics, "payment_page_visited", {
      kitchenName: kitchen?.kitchenName,
    });
  }, [kitchen]);

  const { paySecret, theme, styleProps, options } = configuration;

  const customerName = customerDetails?.customerName
    ? getFirstWordAndFirstLetterOfSecondWord(customerDetails?.customerName)
    : null;

  const customerEmail = customerDetails?.customerEmail
    ? customerDetails.customerEmail
    : null;
  const customerMobileNumber = customerDetails?.customerMobile
    ? customerDetails.customerMobile
    : null;

  let orderType = null;
  if (getOrderType === "dinein") {
    orderType = "Dine In";
  } else {
    orderType = "Take Away";
  }

  // Order object to write
  const order: Order = {
    abn: kitchen?.abn,
    cartItems,
    cartSubtotal,
    createdAt: new Date(),
    customerDetails: {
      customerMobileNumber,
      customerName: customerName,
      customerEmail,
    },
    kitchenAddress: kitchen?.fullAddress,
    kitchenEmail: kitchen?.email,
    kitchenId: kitchen?.kitchenId,
    kitchenName: kitchen?.kitchenName,
    kitchenPhoneNumber: kitchen?.phoneNumber,
    kitchenPushToken: kitchen?.kitchenPushToken
      ? kitchen.kitchenPushToken
      : null,
    orderMethod: "online",
    orderPayedAt: new Date(),
    orderStatus: "New_Order_Processing",
    orderType: orderType,
    orderWaitTime: onlineOrdersConfig?.orderReadyTime
      ? onlineOrdersConfig.orderReadyTime
      : 15,
    paymentStatus: "unpaid",
    paymentType: "card",
    postPay: false,
    scheduledPickupTime: getScheduledTime,
    tableNumber,
    totalAmount: cartTotal, // with all fees (hoilday surcharge, etc..)
    totalQuantity,
    tyroLocationID: onlineOrdersConfig?.tyroLocationId,
    tyroPayRequestId,
    orderId,
    orderNote,
    onlineCardFeeAmount: cardTotalFee,
  };

  let tyro = useRef();
  // STEP 1, Attach TyroJS Library
  // Installs the TyroJS Library onto the DOM
  useEffect(() => {
    // console.log("STEP 1 STARTED");

    const script = document.createElement("script");
    script.id = "tyro-js-library";
    script.src = "https://pay.connect.tyro.com/v1/tyro.js";
    script.crossOrigin = "anonymous";
    script.async = true;
    script.onload = () => setLibraryReady(true);
    document.body.appendChild(script);

    // console.log("STEP 1 DONE");
  }, []);

  // STEP 2, Fetch the Pay Secret
  useEffect(() => {
    setError(null);
    if (
      !paySecret?.length &&
      !fetchingPaySecret &&
      cartItems.length > 0 &&
      customerDetails !== undefined &&
      customerDetails?.customerName !== null
    ) {
      // console.log("STEP 2 STARTED");

      fetchPaySecret();
      // console.log("STEP 2 DONE");
    } else {
      setLoading(false);
    }
  }, [paySecret]);

  // Fetch the Pay Secret
  // This would be replaced with your own implementation to obtain your pay request
  const fetchPaySecret = async () => {
    setFetchingPaySecret(true);
    try {
      const callFunction = httpsCallable(
        functions,
        "tyroEndpoints-createOrder"
      );

      console.log(cartTotal);

      const response: any = await callFunction({
        cartTotal: cartTotal,
        tyroLocationID: onlineOrdersConfig?.tyroLocationId,
      });

      const responseStatus = response.data.statusCode;

      if (responseStatus !== 200) {
        setLoading(false);
        setError({
          type: "critical",
          errorCode: responseStatus,
          message:
            "Error occurred while loading checkout page. Please try again later",
          debug: { response },
        });
      }
      // console.log(responseStatus);
      const paySecret = response.data.paySecret;
      const payRequestId = response.data?.payRequestId
        ? response.data.payRequestId
        : null;
      const orderId = response.data?.orderId ? response.data.orderId : null;

      setOrderId(orderId);
      setTyroPayRequestId(payRequestId);
      setConfiguration({
        ...configuration,
        paySecret,
      });
    } catch (error: any) {
      setError({
        type: "critical",
        errorCode: error.errorCode,
        message: "Failed to generate Payment form. Please try again later.",
        debug: { error },
      });
      setLoading(false);
      setIsFormLoading(false);
    }
    setFetchingPaySecret(false);
  };

  // STEP 3, Load the Pay Request into TyroJS
  useEffect(() => {
    if (libraryReady && paySecret?.length) {
      initPayRequest();
      // console.log("STEP 3 DONE");
    }
  }, [libraryReady, paySecret]);

  // Initialize Tyro.js with the Pay Request
  const initPayRequest = async () => {
    setLoading(true);
    setPayRequestReady(false);
    setPayFormReady(false);
    setSubmitting(false);
    let orderId = null;

    try {
      // @ts-ignore
      tyro.current = new window.Tyro({
        liveMode: tyroLiveMode,
      });

      // @ts-ignore
      await tyro.current.init(paySecret);

      const payFormElement = document.getElementById("tyro-pay-form");
      if (payFormElement !== null) {
        payFormElement.innerHTML = "";
      }
      // @ts-ignore
      const payForm = tyro.current.createPayForm({
        theme,
        styleProps,
        options,
      });
      setLoading(false);

      payForm.setWalletPaymentBeginListener(async paymentType => {
        orderId = await createOnlineOrder(order);

        setError(null);
        setSubmittingOverlay(true);
        setSubmitting(true);

        if (paymentType === "APPLE_PAY") {
          // optionally do something specific to Apple Pay
        } else if (paymentType === "GOOGLE_PAY") {
          // optionally do something specific to Google Pay
        }
      });
      payForm.setWalletPaymentCancelledListener(paymentType => {
        setSubmittingOverlay(false);
        setSubmitting(false);

        if (paymentType === "APPLE_PAY") {
          // optionally do something specific to Apple Pay
        } else if (paymentType === "GOOGLE_PAY") {
          // optionally do something specific to Google Pay
        }
      });

      payForm.setWalletPaymentCompleteListener((paymentType, error) => {
        if (error) {
          setError({
            type: "critical",
            errorCode: error.errorCode,
            message: `${error.toString()}`,
            debug: { error },
          });
          setSubmittingOverlay(false);
          setSubmitting(false);
        } else {
          getPaymentResult(orderId);
        }
      });

      // Javascript code sample
      payForm.setReadyListener(() => {
        // your code to handle presentation, animations or show the pay button
        setIsFormLoading(false);
      });

      payForm.inject("#tyro-pay-form");
      setPayRequestReady(true);
      setPayFormReady(true);
      // console.log("STEP 4 READY");
    } catch (error: any) {
      // console.log("ERROR HERE ====>");
      setError({
        type: "critical",
        errorCode: error.errorCode,
        message: `${error.toString()}`,
        debug: { error },
      });
      setLoading(false);
    }
  };

  // STEP 5, Handle submitting the payment
  async function submitPayForm(event) {
    event.preventDefault(); // Prevent the default form submission behavior

    // if (name !== null && name !== "" && email !== null && email !== "") {
    setError(null);
    setSubmitting(true);
    let result;
    try {
      // write order
      const orderId = await createOnlineOrder(order);
      // @ts-ignore
      result = await tyro.current.submitPay();

      await getPaymentResult(orderId);
    } catch (error: any) {
      if (error?.type === "CLIENT_VALIDATION_ERROR" && !error?.errorCode) {
        // can ignore these errors as handled by validation
      } else {
        // display other errors
        setError({
          type: "critical",
          errorCode: error.errorCode,
          message: `${error.toString()}`,
          debug: { error, result },
        });
      }
      setSubmitting(false);
    }
  }

  async function getPaymentResult(orderId: string) {
    // @ts-ignore

    const payRequest = await tyro.current.fetchPayRequest();

    if (payRequest.status === "SUCCESS") {
      updateOnlineOrder(orderId, order)
        .then((orderCount: string) => {
          setSubmitting(false);

          // const orderType = "takeaway";
          const scheduledTime = "ASAP";
          // dispatch(setOrderType(orderType));
          dispatch(setOrderNote(null));
          dispatch(setScheduledTime(scheduledTime));
          dispatch(clearCart());

          // Reroute to success page
          navigate(`/${kitchenSlug}/checkout/success`, {
            state: { order: { ...order, orderCount } },
          });
        })
        .catch(err => {
          throw err;
        });
    } else {
      setError({
        type: "invalid",
        errorCode: payRequest.errorCode,
        message: `${payRequest.status}`,
        debug: { payRequest },
      });
      setSubmitting(false);
    }
    setSubmittingOverlay(false);
  }

  if (cartItems.length === 0) {
    return (
      <Box
        sx={{
          backgroundColor: headerModalBackground,
          minHeight: "calc(100vh - 72px)",
          "@media screen and (max-width: 768px)": {
            minHeight: "calc(100vh - 64px)",
          },
        }}
      >
        <CheckoutContainer
          style={{
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            paddingTop: "72px",
            paddingBottom: "72px",
          }}
        >
          <img
            src={EmptyCartImage}
            style={{ width: "150px", paddingBottom: "24px" }}
            alt="Empty Cart"
          />
          <EmptyCartText>Your cart is empty</EmptyCartText>
          <EmptyCartSubtitleText>
            Go back to the menu to add some items.
          </EmptyCartSubtitleText>
        </CheckoutContainer>
      </Box>
    );
  }

  if (
    customerDetails === undefined ||
    customerDetails?.customerName === null ||
    customerDetails?.customerEmail === null
  ) {
    return (
      <Box
        sx={{
          backgroundColor: headerModalBackground,
          minHeight: "calc(100vh - 72px)",
          "@media screen and (max-width: 768px)": {
            minHeight: "calc(100vh - 64px)",
          },
        }}
      >
        <CheckoutContainer
          style={{
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            paddingTop: "72px",
            paddingBottom: "72px",
          }}
        >
          {/* <img src={EmptyCartImage} style={{ width: '150px', paddingBottom: '24px' }} alt='Empty Cart' /> */}
          <EmptyCartText>Some of your details are missing</EmptyCartText>
          <EmptyCartSubtitleText>
            Go back to the checkout page to add them
          </EmptyCartSubtitleText>
        </CheckoutContainer>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: headerModalBackground,
        minHeight: "calc(100vh - 72px)",
        "@media screen and (max-width: 768px)": {
          minHeight: "calc(100vh - 64px)",
        },
      }}
    >
      <PaymentContainer ref={tyroJSRef}>
        <PaymentFormContainer onSubmit={submitPayForm}>
          {submitting ? null : (
            <PaymentDetails
              kitchenSlug={kitchenSlug}
              getOrderType={getOrderType}
              getTableNumber={tableNumber}
              cartTotal={cartTotal}
              cartCount={cartCount}
              getScheduledTime={getScheduledTime}
              onlineOrdersConfig={onlineOrdersConfig}
              kitchen={kitchen}
              customerDetails={customerDetails}
            />
          )}
          {((cartItems.length !== 0 && loading) ||
            isFormLoading ||
            submitting) && (
            <SpinnerOverlay backgroundSecondary={backgroundSecondary}>
              <Spinner size={64} color={brandPrimary} />
              {submitting && (
                <SubmittingText>Confirming Order...</SubmittingText>
              )}
            </SpinnerOverlay>
          )}
          <ParentContainer
            overlayState={
              (cartItems.length !== 0 && loading) || isFormLoading || submitting
            }
          >
            <div id="pay-form">
              <div id="tyro-pay-form"></div>
            </div>
            {error?.type && (
              <div
                style={{ color: "red", textAlign: "center", margin: "10px 0" }}
              >
                {error.message}
              </div>
            )}
            <PaymentButton
              id="pay-form-submit"
              buttonType={BUTTON_TYPE_CLASSES.cart}
              text="Pay now"
              disabled={submitting}
              style={{ maxWidth: "100%" }}
              isLoading={undefined}
              cartItems={undefined}
              onClick={undefined}
            />
          </ParentContainer>
        </PaymentFormContainer>
      </PaymentContainer>
    </Box>
  );
};

export default Payment;
