import styled from "styled-components";
import { getFontSize } from "../../utils/utils.common";

interface TitleProps {
  brandAccent: string;
  brandFontStyle: string;
}

interface OrderTypeInfoProps {
  backgroundSecondary: string;
  backgroundTertiary: string;
}

interface CartCountBoxProps {
  headerModalBackground: string;
  brandPrimary: string;
}

interface TitleTextProps {
  backgroundSecondary: string;
}

interface CircleIconProps {
  textBody: string;
}

export const Title = styled.div<TitleProps>`
  color: ${({ brandAccent }) => brandAccent};
  font-family: ${({ brandFontStyle }) => brandFontStyle};
  font-weight: 600;
  font-size: ${({ brandFontStyle }) => getFontSize(brandFontStyle, 24)};
  padding: 32px 16px 24px;
`;

export const OrderTypeInfo = styled.div<OrderTypeInfoProps>`
  padding: 16px;
  background: ${({ backgroundSecondary }) => backgroundSecondary};
  border: 1px solid ${({ backgroundTertiary }) => backgroundTertiary};
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    border-radius: 0px;
  }
`;

export const OrderTypeInfoTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  a {
    display: flex;
    align-items: center;
  }
`;

export const TypeText = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
export const CartCountBox = styled.div<CartCountBoxProps>`
  border-radius: 100px;
  background: ${({ headerModalBackground }) => headerModalBackground};
  color: ${({ brandPrimary }) => brandPrimary};
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
export const OrderTypeInfoBottom = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const TitleText = styled.div<TitleTextProps>`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  padding: 24px 16px;
  border-bottom: 3px solid ${({ backgroundSecondary }) => backgroundSecondary};

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const CircleIcon = styled.div<CircleIconProps>`
  height: 4px;
  width: 4px;
  background: ${({ textBody }) => `${textBody}66`};
  margin: 0 8px;
  border-radius: 50%;
`;
