// import { selectKitchenIsLoading } from '../../store/kitchen/kitchen.selector';
import MenuItem from "../menu-item/menu-item.component";
import { Preview, Title } from "./menu.styles";
import { Element } from "react-scroll";
import { useSelector } from 'react-redux';
import { selectOnlineOrdersConfigs } from '../../store/kitchen/kitchen.selector';
import { getColor } from '../../utils/utils.common';


const MenuComponent = ({ title, products, kitchen, brandFontStyle }) => {
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);
  const brandAccent = getColor("brand-accent", onlineOrdersConfig);

  // Check if any dishes have sequence property. Arrange by sequence, otherwise arrange alphabetically
  let hasSequenceProperty =
    products.some((dish) => dish && dish.hasOwnProperty("sequence")) || false;

  const sortedProducts = products?.sort((a, b) => {
    if (hasSequenceProperty) {
      // Sort based on sequence field, placing those without sequence at the bottom
      if (a.sequence == null) return 1; // a goes to bottom
      if (b.sequence == null) return -1; // b goes to bottom
      return a.sequence - b.sequence;
    } else {
      // Sort dishes alphabetically in each category
      const nameA = a.dishName ? a.dishName : "";
      const nameB = b.dishName ? b.dishName : "";
      return nameA.localeCompare(nameB);
    }
  });

  return (
    <Element name={title}>
      <Title brandAccent={brandAccent} brandFontStyle={brandFontStyle}>{title}</Title>
      <Preview>
        {sortedProducts.map((product) => {
          return (
            <MenuItem key={product.id} product={product} kitchen={kitchen} />
          );
        })}
      </Preview>
    </Element>
  );
};

export default MenuComponent;
