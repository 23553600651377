import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import Home from "./routes/home/home.component";
import Navigation from "./routes/navigation/navigation.component";
import Checkout from "./routes/checkout/checkout.component";
import { useEffect } from "react";

import { useSelector } from "react-redux";

import { GlobalStyle } from "./global.styles";
import CheckoutConfirmation from "./routes/checkout-confirmation/checkout-confirmation.component_new";
import {
  selectKitchen,
  selectOnlineOrdersConfigs,
} from "./store/kitchen/kitchen.selector";
import RedirectPage from "./routes/redirect/redirect.component";
import Payment from "./routes/payment/payment.component";
import ResetPassword from "./routes/authentication/reset-password.component";
import { getColor, getFontName, isOnlineOrderConfigValid } from "./utils/utils.common";
import { ThemeProvider } from "styled-components";

const App = () => {
  // const dispatch = useDispatch();
  const { pathname, hash, key } = useLocation();
  const kitchen = useSelector(selectKitchen);
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);
  
  const theme = {
    fontName: getFontName("secondary-font-style", onlineOrdersConfig),
    textColor: getColor("text-body", onlineOrdersConfig),
    bgColor: getColor("header-modal-background", onlineOrdersConfig),
  };

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change
  return (
    <div>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
      </ThemeProvider>
      <Routes>
        <Route path="/:kitchenSlug">
          <Route index element={<Home />} />
          {/* <Route path="auth" element={<Authentication />} /> */}
          <Route
            path="checkout"
            element={
              <>
                {isOnlineOrderConfigValid(onlineOrdersConfig) ? (
                  <>
                    <Navigation title={kitchen?.kitchenName} logoURL={onlineOrdersConfig?.logoURL} />
                    <Checkout />
                  </>
                ) : (
                  <Navigate to={`/${kitchen?.kitchenSlug}`} replace />
                )}
              </>
            }
          />
          <Route
            path="checkout/success"
            element={
              <>
                {isOnlineOrderConfigValid(onlineOrdersConfig) ? (
                  <>
                    <Navigation title={kitchen?.kitchenName} logoURL={onlineOrdersConfig?.logoURL} />
                    <CheckoutConfirmation />
                  </>
                ) : (
                  <Navigate to={`/${kitchen?.kitchenSlug}`} replace />
                )}
              </>
            }
          >
            {/* <Route path=":session_id" element={<Confirmation />} /> */}
          </Route>

          <Route
            path="checkout/payment"
            element={
              <>
                {isOnlineOrderConfigValid(onlineOrdersConfig) ? (
                  <>
                    <Navigation title={kitchen?.kitchenName} logoURL={onlineOrdersConfig?.logoURL} />
                    <Payment />
                  </>
                ) : (
                  <Navigate to={`/${kitchen?.kitchenSlug}`} replace />
                )}
              </>
            }
          />
        </Route>
        <Route
          path="/"
          element={
            <>
              <Navigation />
              <RedirectPage />
            </>
          }
        />
        <Route
          path="/action"
          element={
            <>
              <Navigation />
              <ResetPassword />
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
