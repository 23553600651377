import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuComponent from "../../components/menu/menu.component";
import Spinner from "../../components/spinner/spinner.component";
import {
  selectKitchenIsLoading,
  selectDishesMap,
  selectKitchen,
  selectOnlineOrdersConfigs,
} from "../../store/kitchen/kitchen.selector";
import {
  HeaderContainer,
  HeaderTitle,
  MenuBody,
  HeaderRight,
  StatusContainer,
  BannerContainer,
  HeaderContent,
  HeaderTextSmall,
  HeaderItem,
  MainHeaderContainer,
  MainHeader,
  IconContainer,
  AdressBox,
} from "./menu.styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MenuHeaders from "../../components/menu-headers/menu-headers.component";
import Footer from "../../components/footer/footer.component";
import CartButton from "../../components/cart-button/cart-button.component";
import {
  selectCartKitchenId,
  selectScheduledTime,
  selectCartCount,
} from "../../store/cart/cart.selector";
import {
  clearCart,
  setOrderNote,
  setScheduledTime,
} from "../../store/cart/cart.action";
import { kitchenClosedAutoSet, kitchenClosedOverride } from "../../constants";
import {
  CheckoutContainer,
  EmptyCartSubtitleText,
  EmptyCartText,
} from "../checkout/checkout.styles";
import NotFoundImage from "../../assets/not-found.png";
import { StandardContainer } from "../../components/menu/menu.styles";
import { SideDrawer } from "../../components/side-drawer/side-drawer";
import { fetchKitchenAsync } from "../../store/kitchen/kitchen.action";
import { Box } from "@mui/material";
import { analytics } from "../../utils/firebase/firebase.utils";
import { logEvent } from "firebase/analytics";
import OrderTypeBottomSheet from "../../components/order-type-bottom-sheet/order-type-bottom-sheet.component";
import {
  getFontName,
  isOnlineOrderConfigValid,
  useMediaQuery,
} from "../../utils/utils.common";
import Status from "../../components/shared/status/status.component";
import OrderTypeButtonGroup from "../../components/shared/buttonGroup/orderTypeButtonGroup.component";
import ButtonAction from "../../components/shared/buttonAction/buttonAction.component";
import { getColor } from "../../utils/utils.common";
import { ClockIcon, HashIcon, MarkerIcon } from "../../components/icons";
import Search from "../../components/shared/search/search.component";
import {
  businessOpenCheck,
  findNextOpening,
  getOpeningOrClosingTime,
} from "../../utils/closedKitchenCheck";

const Menu = () => {
  const { pathname } = useLocation();
  // Get the kitchen name from the url to pass to other compoenent
  const { kitchenSlug } = useParams();
  const searchParams = new URLSearchParams(window.location.search);

  // Get individual query parameters
  const tableNumber = searchParams.get("tableNumber");
  const categoriesMap = useSelector(selectDishesMap);

  // console.log("categoriesMap: ")
  const kitchen = useSelector(selectKitchen);
  const isKitchenOpen = businessOpenCheck(kitchen?.hours?.schedule ?? null);

  const allCategories = kitchen?.dishCategories ? kitchen.dishCategories : null;

  const getScheduledTime = useSelector(selectScheduledTime);
  const onlineOrdersConfig = useSelector(selectOnlineOrdersConfigs);
  const cartCount = useSelector(selectCartCount);

  const getOrderType =
    (tableNumber === "" || tableNumber) && onlineOrdersConfig?.dineInEnabled
      ? "dinein"
      : "takeaway";

  const [currentOrderType, setCurrentOrderType] = useState(getOrderType);

  const textBody = getColor("text-body", onlineOrdersConfig);
  const backgroundTertiary = getColor(
    "background-tertiary",
    onlineOrdersConfig
  );
  const brandPrimary = getColor("brand-primary", onlineOrdersConfig);
  const brandTertiary = getColor("brand-tertiary", onlineOrdersConfig);
  const headerModalBackground = getColor(
    "header-modal-background",
    onlineOrdersConfig
  );
  const fieldBorder = getColor("field-border", onlineOrdersConfig);
  const fieldBackground = getColor("field-background", onlineOrdersConfig);
  const fieldPlaceholderText = getColor(
    "field-placeholder-text",
    onlineOrdersConfig
  );
  const brandFontStyle = getFontName("brand-font-style", onlineOrdersConfig);

  const shouldShowCartButton =
    (kitchen?.kitchenOrderStatus === kitchenClosedAutoSet && isKitchenOpen) ||
    (kitchen?.kitchenOrderStatus !== kitchenClosedAutoSet &&
      kitchen?.kitchenOrderStatus !== kitchenClosedOverride);

  const isLoading = useSelector(selectKitchenIsLoading);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [showOrderTypeBottomSheet, setShowOrderTypeBottomSheet] =
    useState(false);
  const [checkDrawerState, setCheckDrawerState] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const cartKitchenId = useSelector(selectCartKitchenId);
  const dispatch = useDispatch();

  const findNextOpeningTime = findNextOpening(kitchen?.hours?.schedule ?? null);
  const navigate = useNavigate();

  // Function to update URL when table number changes
  const updateTableNumberInURL = newTableNumber => {
    const params = new URLSearchParams(window.location.search);
    if (newTableNumber) {
      params.set("tableNumber", newTableNumber);
    } else {
      params.delete("tableNumber");
    }
    navigate(`${pathname}?${params.toString()}`);
  };
  // console.log("12231231231")
  // console.log(findNextOpeningTime);
  useEffect(() => {
    dispatch(fetchKitchenAsync(pathname));
  }, [dispatch, pathname]);

  useEffect(() => {
    logEvent(analytics, "homepage_visited", {
      kitchenName: kitchen?.kitchenName,
    });
  }, [kitchen]);

  useEffect(() => {
    // Clear Cart if new restaurant is loaded
    if (kitchen?.kitchenId !== cartKitchenId) {
      // const orderType =
      //   onlineOrdersConfig?.takeAwayEnabled && !tableNumber
      //     ? "takeaway"
      //     : "dinein";
      const scheduledTime = "ASAP";
      // console.log("========>>");
      // console.log(orderType);
      // console.log("========>>>>" + tableNumber)
      // dispatch(setOrderType(orderType));
      dispatch(setOrderNote(null));
      dispatch(setScheduledTime(scheduledTime));
      dispatch(clearCart());
    }
  }, [dispatch, cartKitchenId, kitchen, onlineOrdersConfig, tableNumber]);

  useEffect(() => {
    if (onlineOrdersConfig) {
      document.body.style.backgroundColor = getColor(
        "header-modal-background",
        onlineOrdersConfig
      );
    }
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [onlineOrdersConfig]);

  const handlerShowOrderTypeBottomSheet = () => {
    setShowOrderTypeBottomSheet(!showOrderTypeBottomSheet);
  };

  const sortCategories = allDishes => {
    // Create a mapping from category name to category number
    const categoryMap2 = new Map();
    allCategories?.forEach(category => {
      categoryMap2.set(category.name, category.categoryNumber);
    });

    // Function to get the category number from the map key
    const getCategoryNumber = category => {
      const categoryKey = category;
      return categoryMap2.get(categoryKey) || Infinity;
    };

    // Sort the map keys based on the category number
    const sortedMap = new Map(
      [...categoriesMap.entries()].sort((a, b) => {
        // console.log(getCategoryNumber(a[0]))
        return getCategoryNumber(a[0]) - getCategoryNumber(b[0]);
      })
    );

    let content = [];
    for (const [title, dishes] of sortedMap) {
      // Remove the numbers in the category if it starts with a number
      const customTitle = title?.match(/^\d/) ? title.substring(3) : title;
      if (allDishes.size <= 1) {
        content.push(
          <MenuComponent
            key={customTitle}
            products={dishes}
            kitchen={kitchen}
          />
        );
      } else {
        content.push(
          <MenuComponent
            key={customTitle}
            title={customTitle}
            products={dishes}
            kitchen={kitchen}
            brandFontStyle={brandFontStyle}
          />
        );
      }
    }

    return content;
  };

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          left: window.innerWidth / 2 - 35,
          top: window.innerHeight / 2 - 35,
        }}
      >
        <Spinner color={brandPrimary} />
      </div>
    );
  }

  if (kitchen === null && !isLoading) {
    return (
      <CheckoutContainer
        style={{
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          paddingTop: "22px",
          paddingBottom: "22px",
        }}
      >
        <img
          src={NotFoundImage}
          style={{ paddingBottom: "44px" }}
          alt="page-not-found"
        />
        <EmptyCartText>Page not found</EmptyCartText>
        <EmptyCartSubtitleText>
          The page you are looking for doesn’t exist. Check the link and try
          again.
        </EmptyCartSubtitleText>
        <Footer />
      </CheckoutContainer>
    );
  }

  return (
    <Box>
      <MainHeaderContainer
        headerModalBackground={headerModalBackground}
        backgroundTertiary={backgroundTertiary}
        checkDrawerState={checkDrawerState}
      >
        <MainHeader>
          <SideDrawer
            kitchen={kitchen}
            setCheckDrawerState={setCheckDrawerState}
          />
          {onlineOrdersConfig?.logoURL ? (
            <img
              src={onlineOrdersConfig.logoURL}
              style={{
                width: "52px",
                height: "52px",
                display: "block",
                objectFit: "contain",
                visibility: !(openSearch && isMobile) ? "visible" : "hidden",
              }}
              alt=""
            />
          ) : (
            <HeaderTitle
              textBody={textBody}
              brandPrimary={brandPrimary}
              brandFontStyle={brandFontStyle}
              visible={!(openSearch && isMobile)}
            >
              {kitchen?.kitchenName}
            </HeaderTitle>
          )}
          <Search
            openSearch={openSearch}
            setOpenSearch={setOpenSearch}
            fieldBorder={fieldBorder}
            fieldBackground={fieldBackground}
            textBody={textBody}
            fieldPlaceholderText={fieldPlaceholderText}
            brandTertiary={brandTertiary}
            brandPrimary={brandPrimary}
          />
        </MainHeader>
      </MainHeaderContainer>
      <BannerContainer
        imageUrl={onlineOrdersConfig?.bannerImageURL}
        checkDrawerState={checkDrawerState}
      >
        {/* {isOnlineOrderConfigValid(onlineOrdersConfig) &&
            (isKitchenOpen ? (
              <>
                {isOnlineOrderConfigValid(onlineOrdersConfig) && (
                  <StatusContainer>
                    <Status
                      status="open"
                      primaryText="Open (Accepting Orders)"
                      secondaryText={`Closes ${getOpeningOrClosing}`}
                      hasBanner={!!onlineOrdersConfig?.bannerImageURL}
                    />
                  </StatusContainer>
                )}
              </>
            ) : (
              <StatusContainer>
                <Status
                  status="close"
                  primaryText="Closed"
                  secondaryText={`Opens Tomorrow ${getOpeningOrClosing}`}
                  hasBanner={!!onlineOrdersConfig?.bannerImageURL}
                />
              </StatusContainer>
            ))} */}
        {isOnlineOrderConfigValid(onlineOrdersConfig) &&
          onlineOrdersConfig?.onlineOrderingPaused &&
          isKitchenOpen && (
            <StatusContainer>
              <Status
                status="notCurrently"
                primaryText="Currently not accepting online orders."
                hasBanner={!!onlineOrdersConfig?.bannerImageURL}
              />
            </StatusContainer>
          )}

        {isOnlineOrderConfigValid(onlineOrdersConfig) && !isKitchenOpen && (
          <StatusContainer>
            <Status
              status="close"
              primaryText="Closed"
              secondaryText={`Opens ${findNextOpeningTime?.day} at ${findNextOpeningTime?.time}`}
              hasBanner={!!onlineOrdersConfig?.bannerImageURL}
            />
          </StatusContainer>
        )}
        {isOnlineOrderConfigValid(onlineOrdersConfig) &&
          onlineOrdersConfig?.onlineOrderingPaused === false &&
          isKitchenOpen && (
            <>
              {isOnlineOrderConfigValid(onlineOrdersConfig) && (
                <StatusContainer>
                  <Status
                    status="open"
                    primaryText="Open (Accepting Orders)"
                    secondaryText={`Closes ${findNextOpeningTime?.closingTime}`}
                    hasBanner={!!onlineOrdersConfig?.bannerImageURL}
                  />
                </StatusContainer>
              )}
            </>
          )}
        {!isOnlineOrderConfigValid(onlineOrdersConfig) && (
          <StatusContainer>
            <Status
              status="visual"
              primaryText="Visual Menu Only"
              secondaryText="Order From Counter"
              statusCircle={false}
              hasBanner={!!onlineOrdersConfig?.bannerImageURL}
            />
          </StatusContainer>
        )}
      </BannerContainer>

      <HeaderContainer headerModalBackground={headerModalBackground}>
        <StandardContainer>
          <HeaderContent>
            {isOnlineOrderConfigValid(onlineOrdersConfig) &&
              onlineOrdersConfig?.takeAwayEnabled &&
              onlineOrdersConfig?.dineInEnabled &&
              isKitchenOpen && (
                <Box
                  sx={{
                    "@media (min-width:769px)": {
                      marginRight: "16px",
                      flexBasis: "280px",
                      minWidth: "230px",
                    },
                    "@media (max-width: 768px)": {
                      display: "flex",
                      width: "100%",
                    },
                  }}
                >
                  <OrderTypeButtonGroup
                    orderTypes={[
                      { value: "takeaway", label: "Take Away" },
                      { value: "dinein", label: "Dine In" },
                    ]}
                    currentOrderType={currentOrderType}
                    onOrderTypeChange={orderType => {
                      const params = new URLSearchParams(
                        window.location.search
                      );
                      if (orderType === "takeaway") {
                        params.delete("tableNumber");
                      } else {
                        params.set("tableNumber", "");
                      }
                      navigate(`${pathname}?${params.toString()}`);

                      setCurrentOrderType(orderType);
                    }}
                  />
                </Box>
              )}
            <HeaderRight>
              {kitchen?.fullAddress && (
                <HeaderItem specialMargin={true}>
                  <IconContainer textBody={textBody}>
                    <MarkerIcon />
                  </IconContainer>
                  <AdressBox textBody={textBody}>
                    {kitchen.fullAddress}
                  </AdressBox>
                </HeaderItem>
              )}

              {isOnlineOrderConfigValid(onlineOrdersConfig) &&
                (isKitchenOpen &&
                onlineOrdersConfig?.onlineOrderingPaused === false ? (
                  <>
                    {isOnlineOrderConfigValid(onlineOrdersConfig) && (
                      <HeaderItem>
                        {currentOrderType === "dinein" ? (
                          <>
                            <IconContainer textBody={textBody}>
                              <HashIcon />
                            </IconContainer>
                            <HeaderTextSmall
                              textBody={textBody}
                              onClick={handlerShowOrderTypeBottomSheet}
                            >
                              {tableNumber !== null
                                ? `Table ${tableNumber}`
                                : "Enter Table Number"}
                            </HeaderTextSmall>
                          </>
                        ) : (
                          <>
                            <IconContainer textBody={textBody}>
                              <ClockIcon />
                            </IconContainer>
                            <HeaderTextSmall
                              textBody={textBody}
                              onClick={handlerShowOrderTypeBottomSheet}
                            >
                              {getScheduledTime === "ASAP"
                                ? `Pick up ASAP (${onlineOrdersConfig?.orderReadyTime} Mins)`
                                : `Pick Up ${getScheduledTime}`}
                            </HeaderTextSmall>
                          </>
                        )}
                        <div style={{ marginLeft: "12px" }}>
                          <ButtonAction
                            label="Change"
                            onClick={handlerShowOrderTypeBottomSheet}
                            backgroundTertiary={backgroundTertiary}
                            brandPrimary={brandPrimary}
                          />
                        </div>
                      </HeaderItem>
                    )}
                  </>
                ) : null)}
            </HeaderRight>
          </HeaderContent>
        </StandardContainer>
      </HeaderContainer>
      <MenuHeaders
        categoriesMap={categoriesMap}
        allCategories={allCategories}
        headerModalBackground={headerModalBackground}
        textBody={textBody}
        brandPrimary={brandPrimary}
        backgroundTertiary={backgroundTertiary}
      />
      <MenuBody
        backgroundPrimary={getColor("background-primary", onlineOrdersConfig)}
      >
        <StandardContainer>{sortCategories(categoriesMap)}</StandardContainer>
      </MenuBody>
      <div
        style={{
          paddingBottom:
            isOnlineOrderConfigValid(onlineOrdersConfig) &&
            shouldShowCartButton &&
            cartCount > 0
              ? "70px"
              : "0px",
        }}
      >
        <Footer />
      </div>
      {isOnlineOrderConfigValid(onlineOrdersConfig) && (
        <div>
          {shouldShowCartButton && cartCount > 0 && (
            <CartButton
              kitchenSlug={kitchenSlug}
              backgroundTertiary={backgroundTertiary}
              headerModalBackground={headerModalBackground}
              brandPrimary={brandPrimary}
              primaryButtonText={getColor(
                "primary-button-text",
                onlineOrdersConfig
              )}
            />
          )}
        </div>
      )}
      {showOrderTypeBottomSheet && (
        <OrderTypeBottomSheet
          isOpen={showOrderTypeBottomSheet}
          toggleBottomSheet={() => setShowOrderTypeBottomSheet(false)}
          onlineOrdersConfig={onlineOrdersConfig}
          tableNumber={tableNumber}
          orderType={currentOrderType}
        />
      )}
    </Box>
  );
};

export default Menu;
