import { ColorPaletteType } from "./store/kitchen/kitchen.types";

export const kitchenClosedOverride = "Not Accepting Orders";
export const kitchenClosedAutoSet = "Auto";

export const primaryColor = "#8600FF";
export const primaryColorDark = "#6C01CC";
export const secondaryColor = "#E36FE4";
export const baseDark600 = "#353435";
export const baseDark500 = "#3F3F3F";
export const baseLight = "#FFFFFF";
export const fieldError = "#F04438";
export const successColor = "#039855";
export const errorColor = "#D92D20";

export const dishArchived = "Archived"
export const dishSoldout = "Sold Out"
export const dishAvailable = "Available"


// Default colors are used if database colors fail to load.
export const defaultColorPalette: ColorPaletteType  = {
    "brand-font-style": "Urbanist",
    "header-modal-background": "#FFFFFF",
    "card-background": "#FFFFFF",
    "background-secondary": "#F9FAFB",
    "text-body": "#344054",
    "field-border": "#D0D5DD",
    "field-placeholder-text": "#98A2B3",
    "brand-tertiary": "#42307D",
    "primary-button-text": "#FFFFFF",
    "background-tertiary": "#F2F4F7",
    "brand-accent": "#932F19",
    "background-primary": "#FCFCFD",
    "secondary-font-style": "Urbanist",
    "brand-primary": "#6C01CC",
    "field-background": "#FFFFFF",
}
