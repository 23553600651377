import styled from "styled-components";

interface ButtonActionStyledProps {
  backgroundTertiary: string;
  brandPrimary: string;
  size: "small" | "medium" | "large";
}

export const ButtonActionStyled = styled.button<ButtonActionStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ size }) => {
    switch (size) {
      case "large":
        return "3px 15px";
      case "medium":
        return "3px 16px";
      case "small":
        return "3px 11px";
      default:
        return "3px 6px";
    }
  }};

  cursor: pointer;
  border-radius: 100px;
  font-weight: 600;
  font-size: ${({ size }) => (size === "medium" ? "14px" : "16px")};
  transition: all 0.2s ease;

  height: ${({ size }) => {
    switch (size) {
      case "large":
        return "48px";
      case "medium":
        return "44px";
      case "small":
        return "36px";
      default:
        return "30px";
    }
  }};

  border: 1px solid ${({ backgroundTertiary }) => backgroundTertiary};
  background-color: ${({ backgroundTertiary }) => backgroundTertiary};
  color: ${({ brandPrimary }) => brandPrimary};

  &:hover {
    filter: brightness(95%);
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
    height: ${({ size }) => {
      switch (size) {
        case "large":
          return "44px";
        case "medium":
          return "44px";
        case "small":
          return "32px";
        default:
          return "30px";
      }
    }};
  }
`;

export const ButtonIcon = styled.div`
  margin-right: 6px;
  display: flex;
`;
